import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { Row } from "reactstrap";

class GHGIEGraph extends Component<any> {
    Chart = () => {
        const data = this.props.fuelEUData;
        return {
            xAxis: {
                type: 'category',
                data: ['Jan-25', 'Feb-25', 'Mar-25', 'Apr-25', 'May-25', 'Jun-25', 'Jul-25', 'Aug-25', 'Sep-25', 'Oct-25', 'Nov-25', 'Dec-25'],
                axisLabel: {
                    interval: 0,
                    rotate: 45,
                },
            },
            yAxis: {
                type: 'value',
                // min: 90.15,
                // max: 90.40,
                // interval: 0.05,
                axisLabel: {
                    formatter: '{value}',
                },
            },
            series: [
                {
                    data: data?.map((item: any) => {
                        return item.cumulative_ghg_intensity
                    }),
                    type: 'line',
                },
            ],
        };
    };

    render() {
        return (
            <>
                <Row>
                    <ReactEcharts style={{ height: "350px" }} option={this.Chart()} />
                </Row>
            </>
        );
    }
}

export default GHGIEGraph;
