import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
    Col,
    Row,
    Input,
    Label,
    Button,
    Form,
    Modal,
    CardFooter,
    Card,
    CardBody,
    CardHeader,
} from "reactstrap";
import { useQuery } from "react-query";
import { queryKeyes } from "shared/queryKeys";
import ReportDetailsHeader from "Components/ReportDetailsHeader";
import {
    loadCurrentDistanceTravelled,
    loadCurrentSteaingTime,
    loadMainEngineSettings,
    loadVesselMachineries,
    loadVoyageParameterObject,
} from "VesselMaster/vesselMaster.hooks";
import {
    PropellerTypeConstant,
    VesselMachineryConstant,
} from "shared/constants";
import { Field, FieldArray, FieldProps, Formik } from "formik";
import { errorToast, successToast } from "Components/Toasts";
import apiGlobal from "global/api.global";
import { getInputs } from "../EngineerReport.model";
import ToolTip from "Components/ToolTip";
import { TooltipMsg } from "Components/ToolTipMessage";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";
import Loading from "Components/Loading";
import ErrorComponent from "Components/ErrorComponent";
import { commonValidationMessages } from "Components/ValidationErrorMessages";
import { queryClient } from "react-query/queryClient";
import { errResponse } from "GenericForms/Helper";
import env from "environment/env";
import ErrorTooltip from "Components/ErrorTooltip";

interface VoyageParameterType {
    ReportID: number;
    VesselID: number;
    VoyageID: number;
    activeTab: number;
    toggleTab: any;
}

const VoyageParameterComponent = ({
    ReportID,
    VesselID,
    VoyageID,
    activeTab,
    toggleTab,
}: VoyageParameterType) => {
    /** State variables */
    const [vesselEngines, setVesselEngines] = useState<Array<any>>([]);
    let { Voyage_Parameter_inputs } = getInputs("voyage_parameter");
    const [modal_backdrop, setmodal_backdrop] = useState(false);
    const [reportDistanceTravelled, setReportDistanceTravelled] = useState(0);
    const [reportSteamingTime, setReportSteamingTime] = useState(0);
    const [modalIndex, setModalIndex] = useState(0);
    /**State variables end */

    /** Queries */
    /** Vessel Machineries */
    const {
        data: vesselMachineries,
        isLoading: vesselMachineriesLoading,
        isError: vesselMachineriesError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.Machinary.key, VesselID],
        async () => {
            return await loadVesselMachineries(VesselID);
        },
        { staleTime: Infinity }
    );
    /** Main Engine Setting */
    const {
        data: mainEngineSetting,
        isLoading: mainEngineSettingLoading,
        isError: mainEngineSettingError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.MainEngineSetting.key, VesselID],
        async () => {
            return await loadMainEngineSettings(VesselID);
        },
        { staleTime: Infinity }
    );
    /** Steaming Time */
    const {
        data: steamingTime,
        isLoading: steamingTimeLoading,
        isError: steamingTimeError,
    } = useQuery(
        [queryKeyes.vessel.CurrentSteamingTime.key, ReportID],
        async () => {
            return await loadCurrentSteaingTime(ReportID);
        },
        { staleTime: Infinity }
    );
    /** Distance travelled */
    const {
        data: distanceTravelled,
        isLoading: distanceTravelledLoading,
        isError: distanceTravelledError,
    } = useQuery(
        [queryKeyes.vessel.CurrentDistanceTravelled.key, ReportID],
        async () => {
            return await loadCurrentDistanceTravelled(ReportID);
        },
        { staleTime: Infinity }
    );
    /** Voyage Parameter Object used for edit */
    const {
        data: VoyageParameterObject,
        isLoading: VoyageParameterObjectLoading,
        isError: VoyageParameterObjectError,
    } = useQuery(
        [queryKeyes.vessel.VoyageParameterObject.key, VesselID, ReportID],
        async () => {
            return await loadVoyageParameterObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** Queries end */

    /** Assign values to initial object of Voyage Parameter */
    const getInitialVoyageParameterValues = () => {
        let obj: any[] = [];
        if (VoyageParameterObject?.length > 0 && VoyageParameterObject[0].id > 0) {
            return VoyageParameterObject;
        } else if (
            mainEngineSetting !== undefined &&
            vesselMachineries !== undefined &&
            obj.length <= vesselEngines.length
        ) {
            vesselMachineries
                .filter(
                    (item: any) =>
                        item.precedence_id === VesselMachineryConstant.MAIN_ENGINE
                )
                .map((machine: any, index: number) => {
                    obj.push({
                        set_rpm: null,
                        avg_rpm: null,
                        slip_percentage: null,
                        total_revolutions: null,
                        propeller_pitch:
                            mainEngineSetting &&
                            mainEngineSetting
                                .filter((item: any) => item.machinery_name === machine.id)
                                .map((setting: any) => {
                                    if (setting.propeller_type === PropellerTypeConstant.FPP) {
                                        return setting.propeller_pitch;
                                    } else {
                                        return null;
                                    }
                                })[0],
                        machinery_name: machine.id,
                        is_revolution_counter_not_functional: false,
                        vessel: VesselID,
                        voyage_information: VoyageID,
                        vessel_reporting_information: ReportID,
                    });
                    return machine;
                });
            return obj;
        } else {
            return [
                {
                    set_rpm: 0,
                    avg_rpm: 0,
                    slip_percentage: 0,
                    total_revolutions: 0,
                    propeller_pitch: 0,
                    machinery_name: 0,
                    is_revolution_counter_not_functional: false,
                    vessel: VesselID,
                    voyage_information: VoyageID,
                    vessel_reporting_information: ReportID,
                },
            ];
        }
    };

    /**useEffect start */
    useEffect(() => {
        queryClient.invalidateQueries(queryKeyes.vessel.VoyageParameterObject.key);
    }, []);
    /** Create and assign initial object to Formik's initial values' object  */
    useEffect(() => {
        VoyageParameterFormik.initialValues.voyageParameter =
            getInitialVoyageParameterValues();
    }, [vesselMachineries, mainEngineSetting, VoyageParameterObject]);
    /**Get vessel's main engines */
    useEffect(() => {
        if (vesselMachineries !== undefined) {
            setVesselEngines(
                vesselMachineries.filter(
                    (item: any) =>
                        item.precedence_id === VesselMachineryConstant.MAIN_ENGINE
                )
            );
        }
    }, [vesselMachineries]);
    /** Set steaming time & distance travelled from curent report */
    useEffect(() => {
        if (
            steamingTime !== undefined &&
            steamingTime.steaming_time !== undefined
        ) {
            setReportSteamingTime(steamingTime.steaming_time);
        }
        if (
            distanceTravelled !== undefined &&
            distanceTravelled.distance_travelled !== undefined
        ) {
            setReportDistanceTravelled(distanceTravelled.distance_travelled);
        }
    }, [steamingTime, distanceTravelled]);
    /**useEffect end */

    /** Voyage Paramater's Formik object */
    const VoyageParameterFormik = {
        initialValues: {
            voyageParameter: getInitialVoyageParameterValues(),
        },
        validationSchema: Yup.object().shape({
            voyageParameter: Yup.array(
                Yup.object({
                    set_rpm: Yup.string()
                        .matches(
                            /^\d{0,3}(?:\.\d{1,2})?$/,
                            `${commonValidationMessages.before3after2}`
                        )
                        .required(commonValidationMessages.required),
                    is_revolution_counter_not_functional: Yup.boolean(),
                    avg_rpm: Yup.string()
                        .matches(
                            /^\d{0,3}(?:\.\d{1,2})?$/,
                            `${commonValidationMessages.before3after2}`
                        )
                        .when("is_revolution_counter_not_functional", {
                            is: true,
                            then: (schema: any) =>
                                schema.required(commonValidationMessages.required),
                            otherwise: (schema: any) => schema.nullable(),
                        }),
                    total_revolutions: Yup.string()
                        .matches(
                            /^\d{0,7}(?:\.\d{1,2})?$/,
                            `${commonValidationMessages.before7after2}`
                        )
                        .when("is_revolution_counter_not_functional", {
                            is: false,
                            then: (schema: any) =>
                                schema.required(commonValidationMessages.required),
                            otherwise: (schema: any) => schema.nullable(),
                        }),
                    propeller_pitch: Yup.string()
                        .matches(
                            /^\d{0,2}(\.\d{1,10})?$/,
                            `${commonValidationMessages.before2after10}`
                        )
                        .required(commonValidationMessages.required),
                })
            ),
        }),
    };

    /** Open-close modal */
    function tog_backdrop() {
        setmodal_backdrop(!modal_backdrop);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    /** Function to calculate slip */
    const calculateSlipPercentage = (
        form: any,
        values: any,
        name: any,
        value: number,
        idx: number
    ) => {
        let slip_per: number;
        if (
            values?.voyageParameter[idx].is_revolution_counter_not_functional === true
        ) {
            if (name === `voyageParameter.${[idx]}.avg_rpm`) {
                let slip_per =
                    (100 *
                        ((value *
                            reportSteamingTime *
                            values.voyageParameter[idx].propeller_pitch) /
                            1852 -
                            reportDistanceTravelled)) /
                    ((value *
                        reportSteamingTime *
                        values.voyageParameter[idx].propeller_pitch) /
                        1852);
                if (
                    Number.isNaN(slip_per) ||
                    Number.POSITIVE_INFINITY === slip_per ||
                    Number.NEGATIVE_INFINITY === slip_per
                ) {
                    slip_per = 0;
                }
                form.setFieldValue(
                    `voyageParameter.${idx}.slip_percentage`,
                    slip_per.toFixed(2)
                );
            } else if (name === `voyageParameter.${[idx]}.propeller_pitch`) {
                let slip_per =
                    (100 *
                        ((values.voyageParameter[idx].avg_rpm *
                            reportSteamingTime *
                            values.propeller_pitch) /
                            1852 -
                            reportDistanceTravelled)) /
                    ((values.voyageParameter[idx].avg_rpm *
                        reportSteamingTime *
                        values.propeller_pitch) /
                        1852);
                if (
                    Number.isNaN(slip_per) ||
                    Number.POSITIVE_INFINITY === slip_per ||
                    Number.NEGATIVE_INFINITY === slip_per
                ) {
                    slip_per = 0;
                }
                form.setFieldValue(
                    `voyageParameter.${idx}.slip_percentage`,
                    slip_per.toFixed(2)
                );
            }
        } else {
            if (name === `voyageParameter.${[idx]}.total_revolutions`) {
                slip_per =
                    (100 *
                        ((values.voyageParameter[idx].propeller_pitch * value) / 1852 -
                            reportDistanceTravelled)) /
                    ((values.voyageParameter[idx].propeller_pitch * value) / 1852);
                if (
                    Number.isNaN(slip_per) ||
                    Number.POSITIVE_INFINITY === slip_per ||
                    Number.NEGATIVE_INFINITY === slip_per
                ) {
                    slip_per = 0;
                }
                form.setFieldValue(
                    `voyageParameter.${idx}.slip_percentage`,
                    slip_per.toFixed(2)
                );
            } else if (name === `voyageParameter.${[idx]}.propeller_pitch`) {
                slip_per =
                    (100 *
                        ((value * values.voyageParameter[idx].total_revolutions) / 1852 -
                            reportDistanceTravelled)) /
                    ((value * values.voyageParameter[idx].total_revolutions) / 1852);
                if (
                    Number.isNaN(slip_per) ||
                    Number.POSITIVE_INFINITY === slip_per ||
                    Number.NEGATIVE_INFINITY === slip_per
                ) {
                    slip_per = 0;
                }
                form.setFieldValue(
                    `voyageParameter.${idx}.slip_percentage`,
                    slip_per.toFixed(2)
                );
            }
        }
    };

    /** Handle changes of total revolutions & avg RPM */
    const handleVoyageParameterTextboxChanges = (
        form: any,
        values: any,
        name: string,
        e: any,
        idx: number,
        touched: any
    ) => {
        form.setFieldValue(`${name}`, e.target.value);
        if (
            name === `voyageParameter.${[idx]}.total_revolutions` &&
            e.target.value
        ) {
            form.setFieldValue(
                `voyageParameter.${idx}.avg_rpm`,
                (e.target.value / reportSteamingTime).toFixed(2)
            );
        } else if (name === `voyageParameter.${[idx]}.avg_rpm` && e.target.value) {
            form.setFieldValue(
                `voyageParameter.${idx}.total_revolutions`,
                (e.target.value * reportSteamingTime).toFixed(2)
            );
        }
        calculateSlipPercentage(form, values, name, e.target.value, idx);
    };

    return (
        <Card className="p-0 mb-0 border-0">
            <CardHeader className="p-2 border-bottom-0">
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">Voyage Parameters</h4>
                            <p className="card-title-desc pos-start">
                                All readings since last report
                            </p>
                        </Col>
                        <Col>
                            <ReportDetailsHeader />
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            {(vesselMachineriesLoading ||
                mainEngineSettingLoading ||
                steamingTimeLoading ||
                distanceTravelledLoading ||
                VoyageParameterObjectLoading) && (
                    <Loading message="Loading required data!" />
                )}
            {(vesselMachineriesError ||
                mainEngineSettingError ||
                steamingTimeError ||
                distanceTravelledError) && (
                    <ErrorComponent message="Unable to load required data!" />
                )}
            {VoyageParameterObjectError && getInitialVoyageParameterValues()}
            {!(
                vesselMachineriesLoading ||
                mainEngineSettingLoading ||
                steamingTimeLoading ||
                distanceTravelledLoading ||
                VoyageParameterObjectLoading
            ) &&
                !(
                    vesselMachineriesError ||
                    mainEngineSettingError ||
                    steamingTimeError ||
                    distanceTravelledError
                ) && (
                    <Formik
                        onSubmit={(values: any, actions: any) => {
                            actions.setSubmitting(false);
                            const handleResponse = (response: any) => {
                                if (response.status === 201 || response.status === 200) {
                                    successToast("Data saved successfully!");
                                    queryClient.invalidateQueries(
                                        queryKeyes.vessel.VoyageParameterObject.key
                                    );
                                    if (env?.form_validation === true) {
                                        toggleTab(activeTab + 1);
                                    }
                                }
                            };
                            /**Voyage Parameter Submit */
                            if (
                                VoyageParameterObject?.length > 0 &&
                                VoyageParameterObject[0].id > 0
                            ) {
                                values.voyageParameter.map((param: any) => {
                                    apiGlobal
                                        .put(`/voyage_parameter/${param.id}/`, param)
                                        .then((res) => {
                                            handleResponse(res);
                                        })
                                        .catch((err) => {
                                            if (errResponse.includes(err.response.status)) {
                                                errorToast(
                                                    "Internal error occured, please contact the admin"
                                                );
                                            }
                                        });
                                    return "";
                                });
                            } else {
                                apiGlobal
                                    .post(`/voyage_parameter/`, values?.voyageParameter)
                                    .then((res) => {
                                        handleResponse(res);
                                    })
                                    .catch((err) => {
                                        if (errResponse.includes(err?.response?.status)) {
                                            errorToast(
                                                "Internal error occured, please contact the admin"
                                            );
                                        }
                                    });
                            }
                        }}
                        initialValues={VoyageParameterFormik.initialValues}
                        validationSchema={VoyageParameterFormik.validationSchema}
                    >
                        {({
                            values,
                            errors,
                            handleSubmit,
                            handleChange,
                            setErrors,
                            touched,
                            handleBlur,
                        }: {
                            values: any;
                            errors: any;
                            handleSubmit: any;
                            handleChange: any;
                            setErrors: any;
                            touched: any;
                            handleBlur: any;
                        }) => (
                            <Form
                                autoComplete="off"
                                onSubmit={handleSubmit}
                                noValidate
                                className="needs-validation"
                            >
                                <CardBody className="engineer-card-body">
                                    <div className="table-responsive p-0">
                                        <table className="table mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th className="align-middle p-2 sr-no-width">#</th>
                                                    {Voyage_Parameter_inputs.map(
                                                        (input: any, index: number) => {
                                                            return (
                                                                <th
                                                                    key={index}
                                                                    className={`align-middle p-2 ${input.name === "machinery_name"
                                                                        ? "text-left"
                                                                        : "text-center"
                                                                        }`}
                                                                >
                                                                    <div>
                                                                        {input.label}
                                                                        {Object.keys(
                                                                            TooltipMsg.Voyage_Paramters.map(
                                                                                (obj: any) => {
                                                                                    if (obj?.tooltip === true) {
                                                                                        return obj;
                                                                                    }
                                                                                    return null;
                                                                                }
                                                                            )
                                                                        ) &&
                                                                            input.name !== "machinery_name" && (
                                                                                <>
                                                                                    <i
                                                                                        className="bx bx-info-circle"
                                                                                        id={`${input.name}_msg`}
                                                                                    />
                                                                                    <ToolTip
                                                                                        target={`${input.name}_msg`}
                                                                                        message={`${TooltipMsg.Voyage_Paramters.filter(
                                                                                            (item: any) =>
                                                                                                item.target ===
                                                                                                `${input.name}_msg`
                                                                                        ).map((tool: any) => {
                                                                                            return tool.message;
                                                                                        })}`}
                                                                                    />
                                                                                </>
                                                                            )}
                                                                    </div>
                                                                </th>
                                                            );
                                                        }
                                                    )}
                                                    <th className="align-middle p-2 text-center">
                                                        Sensor status
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <FieldArray name="voyageParameter">
                                                    {() => (
                                                        <>
                                                            {vesselEngines.map(
                                                                (vesselEngine: any, idx: number) => {
                                                                    return (
                                                                        <tr key={vesselEngine.id}>
                                                                            <td className="align-middle p-2 text-center">
                                                                                {idx + 1}
                                                                            </td>
                                                                            <td className="align-middle p-2">
                                                                                {vesselEngine.vessel_machinery_name}
                                                                            </td>
                                                                            <td className="align-middle p-2 text-center">
                                                                                <div className="d-inline-block">
                                                                                    <Field
                                                                                        name={`voyageParameter.${idx}.set_rpm`}
                                                                                    >
                                                                                        {({ field, form }: FieldProps) => (
                                                                                            <>
                                                                                                <Input
                                                                                                    type="text"
                                                                                                    className="form-control text-right max-width-7"
                                                                                                    id={`set_rpm_${idx}`}
                                                                                                    name={field.name}
                                                                                                    onBlur={(e: any) => {
                                                                                                        handleBlur(e);
                                                                                                        handleVoyageParameterTextboxChanges(
                                                                                                            form,
                                                                                                            values,
                                                                                                            field.name,
                                                                                                            e,
                                                                                                            idx,
                                                                                                            touched
                                                                                                        );
                                                                                                    }}
                                                                                                    defaultValue={
                                                                                                        values?.voyageParameter[idx]
                                                                                                            ?.set_rpm
                                                                                                    }
                                                                                                />
                                                                                                {errors?.voyageParameter &&
                                                                                                    touched?.voyageParameter &&
                                                                                                    touched?.voyageParameter[idx]
                                                                                                        ?.set_rpm &&
                                                                                                    errors?.voyageParameter[idx]
                                                                                                        ?.set_rpm &&
                                                                                                    env?.form_validation ===
                                                                                                    true &&
                                                                                                    !modal_backdrop && (
                                                                                                        <ErrorTooltip
                                                                                                            target={`set_rpm_${idx}`}
                                                                                                            message={
                                                                                                                errors?.voyageParameter &&
                                                                                                                errors?.voyageParameter[
                                                                                                                    idx
                                                                                                                ]?.set_rpm
                                                                                                            }
                                                                                                            open={
                                                                                                                errors?.voyageParameter &&
                                                                                                                    errors?.voyageParameter[
                                                                                                                        idx
                                                                                                                    ]?.set_rpm
                                                                                                                    ? true
                                                                                                                    : false
                                                                                                            }
                                                                                                        />
                                                                                                    )}
                                                                                            </>
                                                                                        )}
                                                                                    </Field>
                                                                                </div>
                                                                            </td>
                                                                            <td className="align-middle p-2 text-center">
                                                                                <div className="d-inline-block">
                                                                                    <Field
                                                                                        name={`voyageParameter.${idx}.avg_rpm`}
                                                                                    >
                                                                                        {({ field, form }: FieldProps) => (
                                                                                            <>
                                                                                                <Input
                                                                                                    type="text"
                                                                                                    className="form-control text-right max-width-7"
                                                                                                    id={`avg_rpm_${idx}`}
                                                                                                    onBlur={(e: any) => {
                                                                                                        handleBlur(e);
                                                                                                        handleVoyageParameterTextboxChanges(
                                                                                                            form,
                                                                                                            values,
                                                                                                            field.name,
                                                                                                            e,
                                                                                                            idx,
                                                                                                            touched
                                                                                                        );
                                                                                                    }}
                                                                                                    disabled={
                                                                                                        values?.voyageParameter[idx]
                                                                                                            ?.is_revolution_counter_not_functional ===
                                                                                                            true
                                                                                                            ? false
                                                                                                            : true
                                                                                                    }
                                                                                                    value={
                                                                                                        values?.voyageParameter[idx]
                                                                                                            ?.is_revolution_counter_not_functional ===
                                                                                                            false
                                                                                                            ? values.voyageParameter[
                                                                                                                idx
                                                                                                            ]?.avg_rpm ?? 0
                                                                                                            : null
                                                                                                    }
                                                                                                    defaultValue={
                                                                                                        (VoyageParameterObject?.length >
                                                                                                            0 &&
                                                                                                            VoyageParameterObject[0]
                                                                                                                .id) > 0
                                                                                                            ? values.voyageParameter[
                                                                                                                idx
                                                                                                            ]?.avg_rpm ?? 0
                                                                                                            : null
                                                                                                    }
                                                                                                />
                                                                                                {errors?.voyageParameter &&
                                                                                                    touched?.voyageParameter &&
                                                                                                    touched?.voyageParameter[idx]
                                                                                                        ?.avg_rpm &&
                                                                                                    errors?.voyageParameter[
                                                                                                    idx
                                                                                                    ] &&
                                                                                                    env?.form_validation ===
                                                                                                    true &&
                                                                                                    !modal_backdrop && (
                                                                                                        <ErrorTooltip
                                                                                                            target={`avg_rpm_${idx}`}
                                                                                                            message={
                                                                                                                errors?.voyageParameter[
                                                                                                                    idx
                                                                                                                ]?.avg_rpm
                                                                                                            }
                                                                                                            open={
                                                                                                                errors?.voyageParameter[
                                                                                                                    idx
                                                                                                                ]?.avg_rpm
                                                                                                                    ? true
                                                                                                                    : false
                                                                                                            }
                                                                                                        />
                                                                                                    )}
                                                                                            </>
                                                                                        )}
                                                                                    </Field>
                                                                                </div>
                                                                            </td>
                                                                            <td className="align-middle p-2 text-center">
                                                                                <div className="d-inline-block">
                                                                                    <Field
                                                                                        name={`voyageParameter.${idx}.total_revolutions`}
                                                                                    >
                                                                                        {({ field, form }: FieldProps) => (
                                                                                            <Input
                                                                                                type="text"
                                                                                                className="form-control text-right max-width-7"
                                                                                                id={`total_revolutions_${idx}`}
                                                                                                name={field.name}
                                                                                                onBlur={(e: any) => {
                                                                                                    handleBlur(e);
                                                                                                    handleVoyageParameterTextboxChanges(
                                                                                                        form,
                                                                                                        values,
                                                                                                        field.name,
                                                                                                        e,
                                                                                                        idx,
                                                                                                        touched
                                                                                                    );
                                                                                                }}
                                                                                                disabled={
                                                                                                    values?.voyageParameter[idx]
                                                                                                        ?.is_revolution_counter_not_functional ===
                                                                                                        true
                                                                                                        ? true
                                                                                                        : false
                                                                                                }
                                                                                                value={
                                                                                                    values?.voyageParameter[idx]
                                                                                                        ?.is_revolution_counter_not_functional ===
                                                                                                        true
                                                                                                        ? Number.isNaN(
                                                                                                            parseFloat(
                                                                                                                values
                                                                                                                    .voyageParameter[
                                                                                                                    idx
                                                                                                                ]?.total_revolutions
                                                                                                            )
                                                                                                        ) === false
                                                                                                            ? parseFloat(
                                                                                                                values
                                                                                                                    .voyageParameter[
                                                                                                                    idx
                                                                                                                ]?.total_revolutions
                                                                                                            ).toFixed(2)
                                                                                                            : 0
                                                                                                        : null
                                                                                                }
                                                                                                defaultValue={
                                                                                                    VoyageParameterObject?.length >
                                                                                                        0 &&
                                                                                                        VoyageParameterObject[0].id >
                                                                                                        0
                                                                                                        ? values.voyageParameter[
                                                                                                            idx
                                                                                                        ]?.total_revolutions ?? 0
                                                                                                        : null
                                                                                                }
                                                                                            />
                                                                                        )}
                                                                                    </Field>
                                                                                    {errors?.voyageParameter &&
                                                                                        touched?.voyageParameter &&
                                                                                        touched?.voyageParameter[idx]
                                                                                            ?.total_revolutions &&
                                                                                        errors?.voyageParameter[idx] &&
                                                                                        env?.form_validation === true &&
                                                                                        !modal_backdrop && (
                                                                                            <ErrorTooltip
                                                                                                target={`total_revolutions_${idx}`}
                                                                                                message={
                                                                                                    errors?.voyageParameter[idx]
                                                                                                        ?.total_revolutions
                                                                                                }
                                                                                                open={
                                                                                                    errors?.voyageParameter[idx]
                                                                                                        ?.total_revolutions
                                                                                                        ? true
                                                                                                        : false
                                                                                                }
                                                                                            />
                                                                                        )}
                                                                                </div>
                                                                            </td>
                                                                            <td className="align-middle p-2 text-center">
                                                                                <div className="d-inline-block">
                                                                                    <Field
                                                                                        name={`voyageParameter.${idx}.propeller_pitch`}
                                                                                    >
                                                                                        {({ field, form }: FieldProps) => (
                                                                                            <Input
                                                                                                type="text"
                                                                                                className="form-control text-right max-width-7"
                                                                                                id={`propeller_pitch_${idx}`}
                                                                                                name={field.name}
                                                                                                onBlur={(e: any) => {
                                                                                                    handleBlur(e);
                                                                                                    handleVoyageParameterTextboxChanges(
                                                                                                        form,
                                                                                                        values,
                                                                                                        field.name,
                                                                                                        e,
                                                                                                        idx,
                                                                                                        touched
                                                                                                    );
                                                                                                }}
                                                                                                disabled={
                                                                                                    mainEngineSetting !==
                                                                                                        undefined &&
                                                                                                        mainEngineSetting.filter(
                                                                                                            (item: any) =>
                                                                                                                item.machinery_name ===
                                                                                                                vesselEngine.id &&
                                                                                                                item.propeller_type ===
                                                                                                                PropellerTypeConstant.FPP
                                                                                                        ).length > 0
                                                                                                        ? true
                                                                                                        : false
                                                                                                }
                                                                                                defaultValue={
                                                                                                    mainEngineSetting !==
                                                                                                        undefined &&
                                                                                                        mainEngineSetting.length > 0
                                                                                                        ? mainEngineSetting
                                                                                                            .filter(
                                                                                                                (item: any) =>
                                                                                                                    item.machinery_name ===
                                                                                                                    vesselEngine.id &&
                                                                                                                    item.propeller_type ===
                                                                                                                    PropellerTypeConstant.FPP
                                                                                                            )
                                                                                                            .map(
                                                                                                                (ptch: any) =>
                                                                                                                    ptch.propeller_pitch
                                                                                                            )
                                                                                                            .find(
                                                                                                                (pitch: any) =>
                                                                                                                    pitch !== undefined
                                                                                                            )
                                                                                                        : values?.voyageParameter[
                                                                                                            idx
                                                                                                        ].propeller_pitch
                                                                                                }
                                                                                            />
                                                                                        )}
                                                                                    </Field>
                                                                                </div>
                                                                                {errors?.voyageParameter &&
                                                                                    touched?.voyageParameter &&
                                                                                    touched?.voyageParameter[idx]
                                                                                        ?.propeller_pitch &&
                                                                                    errors?.voyageParameter[idx] &&
                                                                                    env?.form_validation === true &&
                                                                                    !modal_backdrop && (
                                                                                        <ErrorTooltip
                                                                                            target={`propeller_pitch_${idx}`}
                                                                                            message={
                                                                                                errors?.voyageParameter[idx]
                                                                                                    ?.propeller_pitch
                                                                                            }
                                                                                            open={
                                                                                                errors?.voyageParameter[idx]
                                                                                                    ?.propeller_pitch
                                                                                                    ? true
                                                                                                    : false
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                            </td>
                                                                            <td className="align-middle p-2 text-center">
                                                                                {steamingTime === undefined ||
                                                                                    steamingTime.steaming_time === 0 ? (
                                                                                    <div className="text-center">
                                                                                        <i
                                                                                            className="mdi mdi-alert-circle-outline icon_s23"
                                                                                            id="steaming_time_caution"
                                                                                        />
                                                                                        <ToolTip
                                                                                            target="steaming_time_caution"
                                                                                            message="The value will be calculated once the steaming time will be updated in 
                                                                                    the report"
                                                                                        />
                                                                                    </div>
                                                                                ) : Number.isNaN(
                                                                                    parseFloat(
                                                                                        values?.voyageParameter[idx]
                                                                                            ?.slip_percentage
                                                                                    )
                                                                                ) ||
                                                                                    parseFloat(
                                                                                        values?.voyageParameter[idx]
                                                                                            ?.slip_percentage
                                                                                    ) === Number.NEGATIVE_INFINITY ||
                                                                                    parseFloat(
                                                                                        values?.voyageParameter[idx]
                                                                                            ?.slip_percentage
                                                                                    ) === Number.POSITIVE_INFINITY ||
                                                                                    values?.voyageParameter[idx]
                                                                                        ?.slip_percentage === null ? (
                                                                                    <p className="mt-2">0.00</p>
                                                                                ) : (
                                                                                    <p className="mb-0">
                                                                                        {parseFloat(
                                                                                            values?.voyageParameter[idx]
                                                                                                ?.slip_percentage
                                                                                        ).toFixed(2)}
                                                                                    </p>
                                                                                )}
                                                                            </td>
                                                                            <td className="p-2 align-middle text-center">
                                                                                <Button
                                                                                    id={`sensor_${idx}`}
                                                                                    color={
                                                                                        values &&
                                                                                        values?.values?.voyageParameter &&
                                                                                        values?.voyageParameter[idx]
                                                                                            ?.is_revolution_counter_not_functional ===
                                                                                        true &&
                                                                                        "danger"
                                                                                    }
                                                                                    className={`btn ${values?.voyageParameter[idx]
                                                                                        ?.is_revolution_counter_not_functional ===
                                                                                        true
                                                                                        ? "btn-danger"
                                                                                        : "functional-status-color"
                                                                                        } btn_size_8_cstm`}
                                                                                    onClick={() => {
                                                                                        tog_backdrop();
                                                                                        setModalIndex(idx);
                                                                                    }}
                                                                                    onMouseOver={() => setModalIndex(idx)}
                                                                                >
                                                                                    {values?.voyageParameter[idx]
                                                                                        ?.is_revolution_counter_not_functional ===
                                                                                        false
                                                                                        ? "Functional"
                                                                                        : "Non-functional"}
                                                                                </Button>
                                                                                <ToolTip
                                                                                    target={`sensor_${idx}`}
                                                                                    message="Click here to change the status"
                                                                                />
                                                                                <Modal
                                                                                    isOpen={modal_backdrop}
                                                                                    toggle={() => {
                                                                                        tog_backdrop();
                                                                                    }}
                                                                                    backdrop={"static"}
                                                                                    id={`sensor-${modalIndex}`}
                                                                                    size="md"
                                                                                >
                                                                                    <div className="modal-header">
                                                                                        <h5
                                                                                            className="modal-title"
                                                                                            id={`sensor-${modalIndex}`}
                                                                                        >
                                                                                            Action
                                                                                        </h5>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn-close"
                                                                                            onClick={() => {
                                                                                                setmodal_backdrop(false);
                                                                                            }}
                                                                                            aria-label="Close"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="modal-body">
                                                                                        <strong>Please Note -</strong>
                                                                                        <p>
                                                                                            {TooltipMsg.Voyage_Paramters.filter(
                                                                                                (item: any) =>
                                                                                                    item.target ===
                                                                                                    "is_revolution_msg"
                                                                                            ).map((tool: any) => {
                                                                                                return tool.message;
                                                                                            })}
                                                                                        </p>
                                                                                        <div className="d-flex flex-wrap gap-2 align-items-center">
                                                                                            <Label for="is_revolution_counter_not_functional">
                                                                                                Revolution counter failure?
                                                                                            </Label>
                                                                                            <Field
                                                                                                name={`voyageParameter.${modalIndex}.is_revolution_counter_not_functional`}
                                                                                            >
                                                                                                {({ field }: FieldProps) => (
                                                                                                    <div className="square-switch sqswitch d-flex align-items-center">
                                                                                                        <Input
                                                                                                            type="checkbox"
                                                                                                            switch="none"
                                                                                                            name={field.name}
                                                                                                            id="is_revolution_counter_not_functional"
                                                                                                            checked={
                                                                                                                values?.voyageParameter[
                                                                                                                    modalIndex
                                                                                                                ]?.is_revolution_counter_not_functional
                                                                                                            }
                                                                                                            onChange={handleChange}
                                                                                                        />
                                                                                                        <Label
                                                                                                            htmlFor="is_revolution_counter_not_functional"
                                                                                                            data-on-label="Yes"
                                                                                                            data-off-label="No"
                                                                                                        ></Label>
                                                                                                    </div>
                                                                                                )}
                                                                                            </Field>
                                                                                        </div>
                                                                                    </div>
                                                                                </Modal>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }
                                                            )}
                                                        </>
                                                    )}
                                                </FieldArray>
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                                <CardFooter className="p-2 py-3">
                                    <Row className="ele_row1">
                                        <div className="d-flex flex-wrap gap-5">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="btn_size_cstm pos-end"
                                                onClick={() => {
                                                    if (env?.form_validation === false) {
                                                        toggleTab(activeTab + 1);
                                                    }
                                                }}
                                            >
                                                Next <i className="bx bx-chevron-right ms-1" />
                                            </Button>
                                            <Button
                                                type="button"
                                                color="primary"
                                                className="btn_size_cstm disabled"
                                                onClick={(e) => {
                                                    setErrors({});
                                                    toggleTab(activeTab - 1);
                                                }}
                                            >
                                                <i className="bx bx-chevron-left me-1" /> Previous
                                            </Button>
                                        </div>
                                    </Row>
                                </CardFooter>
                                <FormValuesDebug values={[values, errors, touched]} />
                            </Form>
                        )}
                    </Formik>
                )}
        </Card>
    );
};
export default VoyageParameterComponent;
