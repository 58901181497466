import React from 'react';
import { Table } from 'reactstrap';
import NotificationTableHead from './NotificationTableHead';
import NotificationTableBody from './NotificationTableBody';
import { CSSModule } from 'reactstrap/types/lib/utils';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';

// Define the type for notification object
interface NotificationType {
    id: number;
    title: string;
    date: string;
    current_state: boolean;
    category: string;
    priority: string;
    innerRef?: React.Ref<HTMLElement>;
    cssModule?: CSSModule;
    [key: string]: any;
}

// Define prop types for the EmailList component
interface NotificationListProps {
    handleNotificationClick: (notification: NotificationType | null) => void;
    notificationObjects: any[];
    notificationLoading: any;
    notificationError: any;
    handleBackClick: any;
    selectedNotification: any;
    notificationUserStatusObject: any[];
    notificationUser: any[];
    activeTab: String;
    notificationStateObject: any[];
    count: number;
    setCount: any;
    refetch: () => any;
}

const NotificationList: React.FC<NotificationListProps> =
    ({
        handleNotificationClick,
        notificationObjects,
        notificationLoading,
        notificationError,
        selectedNotification,
        handleBackClick,
        notificationUserStatusObject,
        notificationUser,
        activeTab,
        notificationStateObject,
        count,
        setCount,
        refetch }: {
            handleNotificationClick: any, notificationObjects: any, notificationLoading: any,
            notificationError: any, selectedNotification: any, handleBackClick: any,
            notificationUserStatusObject: any, notificationUser: any, activeTab: any, notificationStateObject: any,
            count: number,
            setCount: any,
            refetch: any;
        }) => {

        return (
            <>
                {(notificationLoading)
                    && <Loading message='Loading required data!' />}
                {(notificationError)
                    && <ErrorComponent message='Error loading data' />}
                <div className="table-responsive">
                    <Table className="table table-striped table-hover" key={count}>
                        <NotificationTableHead />
                        <NotificationTableBody notification={notificationObjects}
                            handleNotificationClick={handleNotificationClick}
                            selectedNotification={selectedNotification}
                            handleBackClick={handleBackClick}
                            notificationUserStatusObject={notificationUserStatusObject}
                            notificationUser={notificationUser}
                            notificationStateObject={notificationStateObject}
                            activeTab={activeTab}
                            count={count}
                            setCount={setCount}
                            refetch={refetch}
                        />
                    </Table>
                </div>
            </>
        );
    };
export default NotificationList;