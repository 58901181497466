import Layout from 'HorizontalMenu/Menu'
import { RootState } from 'index'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Navbar, Container, Row, Col, Label, Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane, Form, Input } from 'reactstrap'
import { customStyle } from 'shared/CommonCSS'
import { setVesselID } from 'Store/Generic/ReportingSlice'
import classnames from "classnames";
import { Formik, Field, FieldProps } from 'formik'
import { userVessels } from 'utils/auth/authUtils'

const CIISimulator2 = () => {
    /** State variables */
    const dispatch = useDispatch();
    const { Vessels } = useSelector((state: RootState) => state.Reporting);
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);
    const [activeSettingsTab, setActiveSettingsTab] = useState('1');
    let applicableVessels = userVessels();
    /** State variables end */

    const handleVesselSelectionChanges = (e: any) => {
        dispatch(setVesselID(e.id));
    };


    /** Change tabs */
    const toggle = (tab: any) => {
        if (activeSettingsTab !== tab) {
            setActiveSettingsTab(tab);
        }
    }

    /** Handle changes of fields having slider */
    const handleSliderChanges = (
        e: any,
        form: any,
        field1: string,
        field2: string
    ) => {
        const newValue = parseInt(e.target.value);
        const remainingValue = 100 - newValue;
        form.setFieldValue(`${field1}`, newValue);
        form.setFieldValue(`${field2}`, remainingValue);
    };

    /** Vessel Stay's formik object */
    const VesselStayFormik = {
        initialValues: {
            steaming_time: 0,
            non_steaming_time: 0,
            laden: 0,
            ballast: 0,
            anchorage_drifting: 0,
            manoevering: 0,
            port_stay_loading: 0,
            port_stay_discharging: 0,
            port_stay_idle: 0,
        },
    };

    /** Speed Consumption's formik object */
    const SpeedConsumptionFormik = {
        initialValues: {
            ballast_steaming_speed: 0,
            ballast_me_consumption: 0,
            laden_steaming_speed: 0,
            laden_me_consumption: 0,
            anchorage_drifting_fo: 0,
            manoevering_fo: 0,
            port_stay_loading_fo: 0,
            port_stay_discharging_fo: 0,
            port_stay_idle_fo: 0,
            anchorage_drifting_do: 0,
            manoevering_do: 0,
            port_stay_loading_do: 0,
            port_stay_discharging_do: 0,
            port_stay_idle_do: 0,
        },
    };

    /** Machinery Performance's formik object */
    const MachineryPerformanceFormik = {
        initialValues: {
            ballast_passage: 0,
            ballast_avg_achieved_value: 0,
            ballast_sea_trial_value: 0,
            ballast_speed: 0,
            loaded_passage: 0,
            loaded_avg_achieved_value: 0,
            loaded_sea_trial_value: 0,
            loaded_speed: 0,
            steaming: 0,
            anchorage_drifting: 0,
            manoevering: 0,
            port_stay_loading: 0,
            port_stay_discharging: 0,
            port_stay_idle: 0,
            sea_fuel_consumption: 0,
            non_steaming_fuel_consumption: 0,
            discharging_fuel_consumption: 0,
            special_operation_fuel_consumption: 0,
        },
    };

    return (
        <React.Fragment>
            <Layout children={Navbar} />
            <div className="page-content">
                <Container fluid>
                    <Row className="mb-1">
                        <h4>CII Simulator</h4>
                    </Row>
                    <Row className="mb-2">
                        <Col sm={4}>
                            <Label className="mb-0">Select vessel</Label>
                            <Select
                                options={
                                    Vessels.filter((item: any) => item.display === true &&
                                        applicableVessels?.includes(item?.id)) as any[]
                                }
                                getOptionLabel={(option: any) => option.vessel_name}
                                getOptionValue={(option: any) => option.id}
                                onChange={(e: any) => handleVesselSelectionChanges(e)}
                                menuPortalTarget={document.body}
                                styles={customStyle}
                                defaultValue={{
                                    id: VesselID,
                                    vessel_name: vessel?.vessel_name,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="px-2">
                        <Card>
                            <CardBody>
                                <Row className='mb-3'>
                                    <div className='ele_row1'>
                                        <Label className='mt-0 mr-1' htmlFor="remaining_part_of_year">Projector for</Label>
                                        <Col sm={6} className='ps-0'>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="simulatorFilter"
                                                id="remaining_part_of_year"
                                                defaultChecked
                                            />
                                            <Label className='ms-1' htmlFor="following_year">Remaining part of a year</Label><br />
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="simulatorFilter"
                                                id="following_year"
                                            />
                                            <Label className="ms-1">Following year</Label>
                                        </Col>
                                        <Col sm={6}>
                                            <h5>Attained CII - </h5>
                                            <h5>Required CII - </h5>
                                        </Col>
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <h5>CII Projection</h5>
                            </CardHeader>
                            <CardBody>
                                <Nav tabs className='border-0'>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === "1",
                                            })}
                                            onClick={() => {
                                                toggle("1");
                                            }}
                                        >
                                            Vessel Stay
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === "2",
                                            })}
                                            onClick={() => {
                                                toggle("2");
                                            }}
                                        >
                                            Speed & Consumption
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeSettingsTab === "3",
                                            })}
                                            onClick={() => {
                                                toggle("3");
                                            }}
                                        >
                                            Machinery Performance
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeSettingsTab} className="text-muted">
                                    <TabPane tabId="1" className='margin-3'>
                                        <Formik
                                            onSubmit={(values: any, actions: any) => {
                                                actions.setSubmitting(false);
                                            }}
                                            initialValues={VesselStayFormik.initialValues}
                                        >
                                            {(props) => (
                                                <Form>
                                                    <Row className="mb-3">
                                                        <Col sm={2}>
                                                            <Label className="mb-0">
                                                                Steaming time
                                                            </Label>
                                                            <div className="input-group">
                                                                <Field
                                                                    type="text"
                                                                    name="steaming_time"
                                                                    id="steaming_time"
                                                                    className="form-control"
                                                                />
                                                                <div className="input-group-text">
                                                                    %
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <div>
                                                                <Field name={`slider1`}>
                                                                    {({ form }: FieldProps) => (
                                                                        <Input
                                                                            type="range"
                                                                            min={0}
                                                                            max={100}
                                                                            value={props.values.steaming_time}
                                                                            onChange={(e: any) =>
                                                                                handleSliderChanges(
                                                                                    e,
                                                                                    form,
                                                                                    "steaming_time",
                                                                                    "non_steaming_time"
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <Label className="mb-0">
                                                                Non steaming time
                                                            </Label>
                                                            <div className="input-group">
                                                                <Field
                                                                    type="text"
                                                                    name="non_steaming_time"
                                                                    id="non_steaming_time"
                                                                    className="form-control"
                                                                    disabled
                                                                />
                                                                <div className="input-group-text">
                                                                    %
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <h5>
                                                            Vessel condition during steaming time
                                                        </h5>
                                                        <Col sm={2}>
                                                            <Label className="mb-0">Laden</Label>
                                                            <div className="input-group">
                                                                <Field
                                                                    type="text"
                                                                    name="laden"
                                                                    id="laden"
                                                                    className="form-control"
                                                                />
                                                                <div className="input-group-text">
                                                                    %
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <div>
                                                                <Field name={`slider1`}>
                                                                    {({ form }: FieldProps) => (
                                                                        <Input
                                                                            type="range"
                                                                            min={0}
                                                                            max={100}
                                                                            value={props.values.laden}
                                                                            onChange={(e: any) =>
                                                                                handleSliderChanges(
                                                                                    e,
                                                                                    form,
                                                                                    "laden",
                                                                                    "ballast"
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <Label className="mb-0">Ballast</Label>
                                                            <div className="input-group">
                                                                <Field
                                                                    type="text"
                                                                    name="ballast"
                                                                    id="ballast"
                                                                    className="form-control"
                                                                    disabled
                                                                />
                                                                <div className="input-group-text">
                                                                    %
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <h5>Non steaming time</h5>
                                                        <Col sm={2}>
                                                            <Label className="mb-0">
                                                                Anchorage/Drifting
                                                            </Label>
                                                            <Field
                                                                type="text"
                                                                name="anchorage_drifting"
                                                                id="anchorage_drifting"
                                                                className="form-control mb-3"
                                                            />
                                                        </Col>
                                                        <Col sm={2}>
                                                            <div>
                                                                <Field name={`slider1`}>
                                                                    {({ form }: FieldProps) => (
                                                                        <Input
                                                                            type="range"
                                                                            min={0}
                                                                            max={100}
                                                                            value={
                                                                                props.values.anchorage_drifting
                                                                            }
                                                                            onChange={(e: any) =>
                                                                                handleSliderChanges(
                                                                                    e,
                                                                                    form,
                                                                                    "anchorage_drifting",
                                                                                    ""
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <Label className="mb-0">
                                                                Manoevering
                                                            </Label>
                                                            <Field
                                                                type="text"
                                                                name="manoevering"
                                                                id="manoevering"
                                                                className="form-control mb-3"
                                                            />
                                                        </Col>
                                                        <Col sm={2}>
                                                            <div>
                                                                <Field name={`slider1`}>
                                                                    {({ form }: FieldProps) => (
                                                                        <Input
                                                                            type="range"
                                                                            min={0}
                                                                            max={100}
                                                                            value={props.values.manoevering}
                                                                            onChange={(e: any) =>
                                                                                handleSliderChanges(
                                                                                    e,
                                                                                    form,
                                                                                    "manoevering",
                                                                                    ""
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <Label className="mb-0">
                                                                Port stay(loading)
                                                            </Label>
                                                            <Field
                                                                type="text"
                                                                name="port_stay_loading"
                                                                id="port_stay_loading"
                                                                className="form-control mb-3"
                                                            />
                                                        </Col>
                                                        <Col sm={2}>
                                                            <div>
                                                                <Field name={`slider1`}>
                                                                    {({ form }: FieldProps) => (
                                                                        <Input
                                                                            type="range"
                                                                            min={0}
                                                                            max={100}
                                                                            value={
                                                                                props.values.port_stay_loading
                                                                            }
                                                                            onChange={(e: any) =>
                                                                                handleSliderChanges(
                                                                                    e,
                                                                                    form,
                                                                                    "port_stay_loading",
                                                                                    ""
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={2}>
                                                            <Label className="mb-0">
                                                                Port stay(discharging)
                                                            </Label>
                                                            <Field
                                                                type="text"
                                                                name="port_stay_discharging"
                                                                id="port_stay_discharging"
                                                                className="form-control mb-3"
                                                            />
                                                        </Col>
                                                        <Col sm={2}>
                                                            <div>
                                                                <Field name={`slider1`}>
                                                                    {({ form }: FieldProps) => (
                                                                        <Input
                                                                            type="range"
                                                                            min={0}
                                                                            max={100}
                                                                            value={
                                                                                props.values
                                                                                    .port_stay_discharging
                                                                            }
                                                                            onChange={(e: any) =>
                                                                                handleSliderChanges(
                                                                                    e,
                                                                                    form,
                                                                                    "port_stay_discharging",
                                                                                    ""
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <Label className="mb-0">
                                                                Port stay(idle)
                                                            </Label>
                                                            <Field
                                                                type="text"
                                                                name="port_stay_idle"
                                                                id="port_stay_idle"
                                                                className="form-control mb-2"
                                                            />
                                                        </Col>
                                                        <Col sm={2}>
                                                            <div>
                                                                <Field name={`slider1`}>
                                                                    {({ form }: FieldProps) => (
                                                                        <Input
                                                                            type="range"
                                                                            min={0}
                                                                            max={100}
                                                                            value={
                                                                                props.values.port_stay_idle
                                                                            }
                                                                            onChange={(e: any) =>
                                                                                handleSliderChanges(
                                                                                    e,
                                                                                    form,
                                                                                    "port_stay_idle",
                                                                                    ""
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            )}
                                        </Formik>
                                    </TabPane>
                                    <TabPane tabId="2" className='margin-3'>
                                        <Formik
                                            onSubmit={(values: any, actions: any) => {
                                                actions.setSubmitting(false);
                                            }}
                                            initialValues={SpeedConsumptionFormik.initialValues}
                                        >
                                            {(props) => (
                                                <Form>
                                                    <Row className=''>
                                                        <Col sm={4}>
                                                            <h6 className='mb-0'>Weather condition</h6>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="weatherCondition"
                                                                id="fair_weather"
                                                                defaultChecked
                                                            />
                                                            <Label className='ms-1' htmlFor="fair_weather">Fair weather</Label>
                                                            <input
                                                                className="form-check-input ms-2"
                                                                type="radio"
                                                                name="weatherConditon"
                                                                id="all_weather"
                                                            />
                                                            <Label className='ms-1'>All weather</Label>
                                                        </Col>
                                                    </Row>
                                                    <Card className='mb-3'>
                                                        <CardHeader className='p-2'>
                                                            <h5>Steaming period</h5>
                                                        </CardHeader>
                                                        <CardBody className='p-2'>
                                                            <Row className='mb-3'>
                                                                <Col sm={6}>
                                                                    <h5>Ballast condition</h5>
                                                                    <div className='ele_row1'>
                                                                        <Col sm={4}>
                                                                            <Label className="mb-0">
                                                                                Steaming speed
                                                                            </Label>
                                                                            <Field
                                                                                type="text"
                                                                                name="ballast_steaming_speed"
                                                                                id="ballast_steaming_speed"
                                                                                className="form-control"
                                                                            />
                                                                        </Col>
                                                                        <Col sm={4} className='ms-2'>
                                                                            <Label className='mb-0'>ME consumption</Label>
                                                                            <Field
                                                                                type="text"
                                                                                name="ballast_me_consumption"
                                                                                id="ballast_me_consumption"
                                                                                className="form-control"
                                                                            />
                                                                        </Col>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <h5>Laden condition</h5>
                                                                    <div className='ele_row1'>
                                                                        <Col sm={4}>
                                                                            <Label className="mb-0">
                                                                                Steaming speed
                                                                            </Label>
                                                                            <Field
                                                                                type="text"
                                                                                name="laden_steaming_speed"
                                                                                id="laden_steaming_speed"
                                                                                className="form-control"
                                                                            />
                                                                        </Col>
                                                                        <Col sm={4} className='ms-2'>
                                                                            <Label className='mb-0'>ME consumption</Label>
                                                                            <Field
                                                                                type="text"
                                                                                name="laden_me_consumption"
                                                                                id="laden_me_consumption"
                                                                                className="form-control"
                                                                            />
                                                                        </Col>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                    <Card className='mb-3'>
                                                        <CardHeader className='p-2'>
                                                            <h5>Non steaming period</h5>
                                                        </CardHeader>
                                                        <CardBody className='p-2'>
                                                            <Row>
                                                                <Col sm={3}>
                                                                    <h5 className="text-center">Operation</h5>
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <h5 className="text-center">FO</h5>
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <h5 className="text-center">DO</h5>
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-3">
                                                                <Col sm={3}>
                                                                    <Label>Anchorage/Drifting</Label>
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <Field
                                                                        type="text"
                                                                        name="anchorage_drifting_fo"
                                                                        id="anchorage_drifting_fo"
                                                                        className="form-control"
                                                                    />
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <Field
                                                                        type="text"
                                                                        name="anchorage_drifting_do"
                                                                        id="anchorage_drifting_do"
                                                                        className="form-control"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-3">
                                                                <Col sm={3}>
                                                                    <Label>Manoevering</Label>
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <Field
                                                                        type="text"
                                                                        name="manoevering_fo"
                                                                        id="manoevering_fo"
                                                                        className="form-control"
                                                                    />
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <Field
                                                                        type="text"
                                                                        name="manoevering_do"
                                                                        id="manoevering_do"
                                                                        className="form-control"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-3">
                                                                <Col sm={3}>
                                                                    <Label>Port stay(loading)</Label>
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <Field
                                                                        type="text"
                                                                        name="port_stay_loading_fo"
                                                                        id="port_stay_loading_fo"
                                                                        className="form-control"
                                                                    />
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <Field
                                                                        type="text"
                                                                        name="port_stay_loading_do"
                                                                        id="port_stay_loading_do"
                                                                        className="form-control"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-3">
                                                                <Col sm={3}>
                                                                    <Label>Port stay (discharging)</Label>
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <Field
                                                                        type="text"
                                                                        name="port_stay_discharging_fo"
                                                                        id="port_stay_discharging_fo"
                                                                        className="form-control"
                                                                    />
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <Field
                                                                        type="text"
                                                                        name="port_stay_discharging_do"
                                                                        id="port_stay_discharging_do"
                                                                        className="form-control"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm={3}>
                                                                    <Label>Port stay(idle)</Label>
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <Field
                                                                        type="text"
                                                                        name="port_stay_idle_fo"
                                                                        id="port_stay_idle_fo"
                                                                        className="form-control"
                                                                    />
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <Field
                                                                        type="text"
                                                                        name="port_stay_idle_do"
                                                                        id="port_stay_idle_do"
                                                                        className="form-control"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Form>
                                            )}
                                        </Formik>
                                    </TabPane>
                                    <TabPane tabId="3" className='margin-3'>
                                        <Formik
                                            onSubmit={(values: any, actions: any) => {
                                                actions.setSubmitting(false);
                                            }}
                                            initialValues={MachineryPerformanceFormik.initialValues}
                                        >
                                            {(props) => (
                                                <Form>
                                                    <Card>
                                                        <CardHeader className='p-2'>
                                                            <h5>Main Engine SFOC</h5>
                                                        </CardHeader>
                                                        <CardBody className='p-2'>
                                                            <Row className='mb-0'>
                                                                <Col sm={2}>
                                                                    <Label className='mb-0'>Ballast passage</Label>
                                                                    <Field
                                                                        type="text"
                                                                        id="ballast_passage"
                                                                        name="ballast_passage"
                                                                        className="form-control"
                                                                    />
                                                                </Col>
                                                                <Col sm={{ size: 2, offset: 3 }}>
                                                                    <Label className='mb-0'>Average achieved value</Label>
                                                                    <Field
                                                                        type="text"
                                                                        id="ballast_avg_achieved_value"
                                                                        name="ballast_avg_achieved_value"
                                                                        className="form-control"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row className='mb-2'>
                                                                <Col sm={{ size: 3, offset: 2 }} className='ele_row1'>
                                                                    <div>
                                                                        <Label className='mb-0'>Sea trial value</Label>
                                                                        <Field
                                                                            type="text"
                                                                            id='ballast_sea_trial_value'
                                                                            name='ballast_sea_trial_value'
                                                                            className="form-control max-width-7"
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className='d-flex align-items-center justify-content-center ms-2 me-2'>
                                                                        <Label className='mb-0 text-center'>at</Label>
                                                                    </div>
                                                                    <div>
                                                                        <Label className='mb-0'>Speed</Label>
                                                                        <Field
                                                                            type="text"
                                                                            id='ballast_speed'
                                                                            name='ballast_speed'
                                                                            className="form-control max-width-7"
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className='mb-0'>
                                                                <Col sm={2}>
                                                                    <Label className='mb-0'>Loaded passage</Label>
                                                                    <Field
                                                                        type="text"
                                                                        id="loaded_passage"
                                                                        name="loaded_passage"
                                                                        className="form-control"
                                                                    />
                                                                </Col>
                                                                <Col lg={{ size: 2, offset: 3 }}>
                                                                    <Label className='mb-0'>Average achieved value</Label>
                                                                    <Field
                                                                        type="text"
                                                                        id="loaded_avg_achieved_value"
                                                                        name="loaded_avg_achieved_value"
                                                                        className="form-control"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row className='mb-3'>
                                                                <Col sm={{ size: 3, offset: 2 }} className='ele_row1'>
                                                                    <div>
                                                                        <Label className='mb-0'>Sea trial value</Label>
                                                                        <Field
                                                                            type="text"
                                                                            id='loaded_sea_trial_value'
                                                                            name='loaded_sea_trial_value'
                                                                            className="form-control max-width-7"
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className='d-flex align-items-center justify-content-center ms-2 me-2'>
                                                                        <Label className='mb-0 text-center'>at</Label>
                                                                    </div>
                                                                    <div>
                                                                        <Label className='mb-0'>Speed</Label>
                                                                        <Field
                                                                            type="text"
                                                                            id='loaded_speed'
                                                                            name='loaded_speed'
                                                                            className="form-control max-width-7"
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                    <Card>
                                                        <CardHeader className='p-2'>
                                                            <h5>Auxilliary engine consumption</h5>
                                                        </CardHeader>
                                                        <CardBody className='p-2'>
                                                            <Row>
                                                                <h6 className="text-dark">Fuel consumption mt/day</h6>
                                                                <Col sm={2}>
                                                                    <Label className='mb-0'>Steaming</Label>
                                                                    <Field
                                                                        type="text"
                                                                        name="steaming"
                                                                        id="steaming"
                                                                        className="form-control mb-3"
                                                                    />
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <div>
                                                                        <Field name={`slider1`}>
                                                                            {({ form }: FieldProps) => (
                                                                                <Input
                                                                                    type="range"
                                                                                    min={0}
                                                                                    max={100}
                                                                                    value={props.values.steaming}
                                                                                    onChange={(e: any) => handleSliderChanges(e, form, 'steaming', '')}
                                                                                    className='mt-4'
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <Label className="mb-0">
                                                                        Anchorage/Drifting
                                                                    </Label>
                                                                    <Field
                                                                        type="text"
                                                                        name="anchorage_drifting"
                                                                        id="anchorage_drifting"
                                                                        className="form-control mb-3"
                                                                    />
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <div>
                                                                        <Field name={`slider1`}>
                                                                            {({ form }: FieldProps) => (
                                                                                <Input
                                                                                    type="range"
                                                                                    min={0}
                                                                                    max={100}
                                                                                    value={
                                                                                        props.values
                                                                                            .anchorage_drifting
                                                                                    }
                                                                                    onChange={(e: any) =>
                                                                                        handleSliderChanges(
                                                                                            e,
                                                                                            form,
                                                                                            "anchorage_drifting",
                                                                                            ""
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <Label className="mb-0">
                                                                        Manoevering
                                                                    </Label>
                                                                    <Field
                                                                        type="text"
                                                                        name="manoevering"
                                                                        id="manoevering"
                                                                        className="form-control mb-3"
                                                                    />
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <div>
                                                                        <Field name={`slider1`}>
                                                                            {({ form }: FieldProps) => (
                                                                                <Input
                                                                                    type="range"
                                                                                    min={0}
                                                                                    max={100}
                                                                                    value={
                                                                                        props.values.manoevering
                                                                                    }
                                                                                    onChange={(e: any) =>
                                                                                        handleSliderChanges(
                                                                                            e,
                                                                                            form,
                                                                                            "manoevering",
                                                                                            ""
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm={2}>
                                                                    <Label className="mb-0">
                                                                        Port stay(loading)
                                                                    </Label>
                                                                    <Field
                                                                        type="text"
                                                                        name="port_stay_loading"
                                                                        id="port_stay_loading"
                                                                        className="form-control mb-3"
                                                                    />
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <div>
                                                                        <Field name={`slider1`}>
                                                                            {({ form }: FieldProps) => (
                                                                                <Input
                                                                                    type="range"
                                                                                    min={0}
                                                                                    max={100}
                                                                                    value={
                                                                                        props.values
                                                                                            .port_stay_loading
                                                                                    }
                                                                                    onChange={(e: any) =>
                                                                                        handleSliderChanges(
                                                                                            e,
                                                                                            form,
                                                                                            "port_stay_loading",
                                                                                            ""
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <Label className="mb-0">
                                                                        Port stay(discharging)
                                                                    </Label>
                                                                    <Field
                                                                        type="text"
                                                                        name="port_stay_discharging"
                                                                        id="port_stay_discharging"
                                                                        className="form-control mb-3"
                                                                    />
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <div>
                                                                        <Field name={`slider1`}>
                                                                            {({ form }: FieldProps) => (
                                                                                <Input
                                                                                    type="range"
                                                                                    min={0}
                                                                                    max={100}
                                                                                    value={
                                                                                        props.values
                                                                                            .port_stay_discharging
                                                                                    }
                                                                                    onChange={(e: any) =>
                                                                                        handleSliderChanges(
                                                                                            e,
                                                                                            form,
                                                                                            "port_stay_discharging",
                                                                                            ""
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <Label className="mb-0">
                                                                        Port stay(idle)
                                                                    </Label>
                                                                    <Field
                                                                        type="text"
                                                                        name="port_stay_idle"
                                                                        id="port_stay_idle"
                                                                        className="form-control mb-2"
                                                                    />
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <div>
                                                                        <Field name={`slider1`}>
                                                                            {({ form }: FieldProps) => (
                                                                                <Input
                                                                                    type="range"
                                                                                    min={0}
                                                                                    max={100}
                                                                                    value={
                                                                                        props.values.port_stay_idle
                                                                                    }
                                                                                    onChange={(e: any) =>
                                                                                        handleSliderChanges(
                                                                                            e,
                                                                                            form,
                                                                                            "port_stay_idle",
                                                                                            ""
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                    <Card>
                                                        <CardHeader className='p-2'>
                                                            <h5>Boiler consumption</h5>
                                                        </CardHeader>
                                                        <CardBody className='p-2'>
                                                            <Row>
                                                                <h6 className="text-dark">Fuel consumption mt/day</h6>
                                                                <Col sm={2}>
                                                                    <Label className='mb-0'>During steaming</Label>
                                                                    <Field
                                                                        type="text"
                                                                        name="sea_fuel_consumption"
                                                                        id="sea_fuel_consumption"
                                                                        className="form-control mb-3"
                                                                    />
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <div>
                                                                        <Field name={`slider1`}>
                                                                            {({ form }: FieldProps) => (
                                                                                <Input
                                                                                    type="range"
                                                                                    min={0}
                                                                                    max={100}
                                                                                    value={
                                                                                        props.values
                                                                                            .sea_fuel_consumption
                                                                                    }
                                                                                    onChange={(e: any) =>
                                                                                        handleSliderChanges(
                                                                                            e,
                                                                                            form,
                                                                                            "sea_fuel_consumption",
                                                                                            ""
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <Label className='mb-0'>During non steaming</Label>
                                                                    <Field
                                                                        type="text"
                                                                        name="non_steaming_fuel_consumption"
                                                                        id="non_steaming_fuel_consumption"
                                                                        className="form-control mb-3"
                                                                    />
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <div>
                                                                        <Field name={`slider1`}>
                                                                            {({ form }: FieldProps) => (
                                                                                <Input
                                                                                    type="range"
                                                                                    min={0}
                                                                                    max={100}
                                                                                    value={
                                                                                        props.values
                                                                                            .non_steaming_fuel_consumption
                                                                                    }
                                                                                    onChange={(e: any) =>
                                                                                        handleSliderChanges(
                                                                                            e,
                                                                                            form,
                                                                                            "non_steaming_fuel_consumption",
                                                                                            ""
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                    <Card>
                                                        <CardHeader className='p-2'>
                                                            <h5>Inert gas generator consumption</h5>
                                                        </CardHeader>
                                                        <CardBody className='p-2'>
                                                            <Row>
                                                                <h6 className="text-dark">
                                                                    Fuel consumption mt/day
                                                                </h6>
                                                                <Col sm={2}>
                                                                    <Label className="mb-0">
                                                                        During discharging operation
                                                                    </Label>
                                                                    <Field
                                                                        type="text"
                                                                        name="discharging_fuel_consumption"
                                                                        id="discharging_fuel_consumption"
                                                                        className="form-control mb-3"
                                                                    />
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <div>
                                                                        <Field name={`slider1`}>
                                                                            {({ form }: FieldProps) => (
                                                                                <Input
                                                                                    type="range"
                                                                                    min={0}
                                                                                    max={100}
                                                                                    value={
                                                                                        props.values
                                                                                            .discharging_fuel_consumption
                                                                                    }
                                                                                    onChange={(e: any) =>
                                                                                        handleSliderChanges(
                                                                                            e,
                                                                                            form,
                                                                                            "discharging_fuel_consumption",
                                                                                            ""
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <Label className="mb-0">
                                                                        During special operation
                                                                    </Label>
                                                                    <Field
                                                                        type="text"
                                                                        name="special_operation_fuel_consumption"
                                                                        id="special_operation_fuel_consumption"
                                                                        className="form-control mb-3"
                                                                    />
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <div>
                                                                        <Field name={`slider1`}>
                                                                            {({ form }: FieldProps) => (
                                                                                <Input
                                                                                    type="range"
                                                                                    min={0}
                                                                                    max={100}
                                                                                    value={
                                                                                        props.values
                                                                                            .special_operation_fuel_consumption
                                                                                    }
                                                                                    onChange={(e: any) =>
                                                                                        handleSliderChanges(
                                                                                            e,
                                                                                            form,
                                                                                            "special_operation_fuel_consumption",
                                                                                            ""
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Form>
                                            )}
                                        </Formik>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CIISimulator2