import React, { useState, useEffect } from 'react';
import { CardBody, Col, Row } from "reactstrap";
import '../global/GlobalCSS.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '..';
import { VesselState, getVoyagesAction, setVesselID, setVesselState, setVoyageID } from "../Store/Generic/ReportingSlice";
import CreateVoyage from './CreateVoyage';
import ToolTip from '../Components/ToolTip';
import VesselReportingIcon from '../Media/vesselReporting.png';
import DeletePopOver from '../Components/DeletePopOver';
import { voyageList } from 'VesselMaster/vesselMaster.hooks';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { successToast, voyageErrorToast } from 'Components/Toasts';
import apiGlobal from 'global/api.global';
import { hasRole } from 'utils/auth/authUtils';
import { Roles, VoyageConstant } from 'shared/constants';
import PopOver from 'Components/PopOver';
import { queryClient } from 'react-query/queryClient';
import VoyageTableHeadComponent from './VoyageTableHeadComponent';
import EditIcon from '../Media/ED2.png';
import DeleteIcon from '../Media/delete.png';
import CompleteVoyageIcon from '../Media/doubleTick.png'

const OngoingVoyage = () => {
    const dispatch = useDispatch();
    const { VesselID, VesselState, VoyageID } = useSelector((state: RootState) => state.Reporting);
    const [deletePopOverState, setDeletePopOverState] = useState(false);
    const [completeVoyBoolean, setCompleteVoyBoolean] = useState(false);
    const [voyIndex, setVoyIndex] = useState(0);
    const [voyObj, setVoyObj] = useState<any>({});
    const handleVesselState = (rowID: number, voyageID: number, record: VesselState) => {
        dispatch(setVesselID(rowID))
        dispatch(setVoyageID(voyageID))
        dispatch(setVesselState(record))
    }

    useEffect(() => {
        dispatch(getVoyagesAction('voyage_information' as string));
    }, [dispatch]);

    const { data: voyages, isFetching: voyagesFetching, isError: voyagesError } = useQuery([queryKeyes.vessel.VoyageList.key, VesselID],
        async () => await voyageList(VesselID), {
        staleTime: 600000
    })

    const handleCloseVoyage = async (voyage: any) => {
        try {
            voyage.voyage_status = VoyageConstant.CLOSED;
            const response = await apiGlobal.put(`/voyage_information/${voyage.id}/`, voyage).then(res => {
                if (res.status === 200) {
                    setCompleteVoyBoolean(false);
                    dispatch(getVoyagesAction('voyage_information' as string));
                    successToast("Voyage completed successfully!");
                }
            });
            return response;
        } catch (err) {
            return null;
        }
    }

    const handleDeleteVoyage = async (voyage: any) => {
        try {
            voyage.voyage_status = VoyageConstant.CLOSED;
            const response = await apiGlobal.delete(`/voyage_information/${voyage.id}/`, voyage).then(res => {
                if (res.status === 200 || res.status === 204) {
                    setDeletePopOverState(false);
                    queryClient.invalidateQueries(queryKeyes.vessel.VoyageList.key);
                    successToast("Voyage deleted successfully!");
                }
            });
            return response;
        } catch (err) {
            return null;
        }
    }

    return (
        <>
            <React.Fragment>
                <CardBody className='p-0'>
                    <Row className='d-flex'>
                        <Col className='' sm={6}>
                            <h4 id='owner_table'>Voyage Reporting Information</h4>
                        </Col>
                        <Col sm={{ size: 2, offset: 4 }} className='text-right'>
                            {(hasRole(Roles.ES_ADMIN) || hasRole(Roles.MASTER)) &&
                                <button
                                    color='primary'
                                    className='btn btn-primary mb-3'
                                    onClick={async () => {
                                        await apiGlobal.get(
                                            queryKeyes.vessel.CheckNewVoyage.url(VesselID)
                                        ).then((res: any) => {
                                            if (res.data.voyage === false) {
                                                handleVesselState(VesselID, -1, 'CREATE_VOYAGE_REPORTING');
                                            } else {
                                                voyageErrorToast(res.data.detail);
                                            }
                                        }).catch(err => {
                                            console.log(err);
                                        });
                                    }}>
                                    <i className="dripicons-plus font-size-16 align-middle me-2" />
                                    Create New
                                </button>}
                        </Col>
                    </Row>
                    <div className="table-responsive" id='owner_table'>
                        {(voyagesFetching) && (
                            <div
                                className="alert-border-left mb-0 alert alert-info alert-dismissible fade show"
                                role="alert">
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                <strong>Loading</strong> - Voyage list is loading</div>

                        )}
                        {voyagesError && (
                            <div className="alert-border-left alert alert-danger alert-dismissible fade show" role="alert">
                                <button type="button" className="btn-close" aria-label="Close"></button>
                                <i className="mdi mdi-block-helper me-3 align-middle"></i>
                                <strong>Error</strong> - Unable to load voyage list!</div>

                        )}
                        {((!voyagesFetching && !voyagesError) && voyages.length > 0) &&
                            <table className="table mb-0">
                                <VoyageTableHeadComponent />
                                <tbody>
                                    {voyages.filter((item: any) => item.display === true &&
                                        (item.voyage_status === VoyageConstant.NEWVOYAGE || item.voyage_status === VoyageConstant.ONGOING))
                                        .map((Voyage: any, index: number) => (
                                            <tr key={Voyage.id}>
                                                <td className="p-2 align-middle text-center">{index + 1}</td>
                                                <td className="p-2 align-middle">{Voyage.voyage_number}</td>
                                                <td className="p-2 align-middle text-left">{Voyage.voyage_status === 1 ? <p>New</p> : <p>Ongoing</p>}</td>

                                                <td className="p-2 align-middle text-center">
                                                    {Voyage?.voyage_status === VoyageConstant.NEWVOYAGE &&
                                                        <ToolTip target='edit_details' message='Edit voyage details' /> &&
                                                        (
                                                            hasRole(Roles.ES_ADMIN) ||
                                                            hasRole(Roles.MANAGER)
                                                        ) &&
                                                        <>
                                                            <img
                                                                src={EditIcon}
                                                                alt="Edit Icon"
                                                                height="28"
                                                                className='pointer'
                                                                id='edit_details'
                                                                onClick={() => { handleVesselState(VesselID, Voyage.id, 'VOYAGE_REPORTING_EDIT') }}
                                                            />
                                                            <ToolTip target='edit_details' message='Edit voyage details' />
                                                        </>
                                                    }
                                                </td>
                                                <td className="p-2 align-middle text-center">
                                                    {/* {(
                                                    hasRole(Roles.ES_ADMIN) ||
                                                    hasRole(Roles.MASTER)
                                                ) &&
                                                    <>
                                                        <i className='dripicons-preview icon_s18 mr-2' onClick={() => {
                                                            tog_backdrop_view();
                                                        }}
                                                            data-toggle="modal"
                                                            id='view_details'
                                                        ></i>
                                                        <ToolTip target='view_details' message='View details' />
                                                    </>} */}
                                                    {(
                                                        hasRole(Roles.ES_ADMIN) ||
                                                        hasRole(Roles.MASTER) ||
                                                        hasRole(Roles.CHIEFENGINEER) ||
                                                        hasRole(Roles.MANAGER)
                                                    ) && <>
                                                            <ToolTip target='vessel_reporting' message='Vessel reporting' />
                                                            <img alt=""
                                                                src={VesselReportingIcon}
                                                                height="32"
                                                                id="vessel_reporting"
                                                                className='pointer'
                                                                onClick={() => {
                                                                    handleVesselState(VesselID, Voyage.id, 'VESSEL_REPORTING');
                                                                }}
                                                            />
                                                        </>
                                                    }
                                                </td>
                                                <td className="p-2 align-middle text-center">
                                                    {(
                                                        hasRole(Roles.ES_ADMIN) ||
                                                        hasRole(Roles.MASTER)
                                                    ) &&
                                                        <>
                                                            <PopOver
                                                                target={`complete_voyage${voyIndex}`}
                                                                handleClick={() => handleCloseVoyage(voyObj)}
                                                                message={'Are you sure you want to complete the voyage?'}
                                                                state={completeVoyBoolean}
                                                                setState={setCompleteVoyBoolean}
                                                            />
                                                            <ToolTip target={`complete_voyage${index}`} message='Complete voyage' />
                                                            <img alt=""
                                                                src={CompleteVoyageIcon}
                                                                height="28"
                                                                className='pointer'
                                                                id={`complete_voyage${index}`}
                                                                onClick={() => {
                                                                    setVoyIndex(index);
                                                                    setVoyObj(Voyage);
                                                                    setCompleteVoyBoolean(true);
                                                                }}
                                                            />
                                                        </>
                                                    }
                                                </td>
                                                <td className="p-2 align-middle text-center">
                                                    {(hasRole(Roles.ES_ADMIN)) &&
                                                        <>
                                                            <img alt=""
                                                                src={DeleteIcon}
                                                                height="28"
                                                                className='pointer'
                                                                id={`delete_voyage${index}`}
                                                                onClick={() => {
                                                                    setVoyIndex(index);
                                                                    setDeletePopOverState(true);
                                                                }}
                                                            />
                                                            <ToolTip target={`delete_voyage${index}`} message='Delete voyage' />
                                                            <DeletePopOver
                                                                target={`delete_voyage${voyIndex}`}
                                                                onClick={() => handleDeleteVoyage(Voyage)}
                                                                state={deletePopOverState}
                                                                setState={setDeletePopOverState}
                                                            />
                                                        </>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        }
                    </div>
                </CardBody>
            </React.Fragment>
            {VesselState === 'CREATE_VOYAGE_REPORTING' && <CreateVoyage />}
            {VesselState === 'VOYAGE_REPORTING_EDIT' && <CreateVoyage voyageId={VoyageID} />}
        </>
    )
}



export default OngoingVoyage;