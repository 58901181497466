import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Navbar, Row } from 'reactstrap';
import Layout from 'HorizontalMenu/Menu';
import Compose from './Compose';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { loadCommonNotificationURL, loadNotificationState, loadNotificationUser, loadNotificationUserStatus } from 'VesselMaster/vesselMaster.hooks';
import Sidebar from './Sidebar';
import NotificationList from './NotificationList';
import { useParams } from 'react-router-dom';
import ErrorComponent from 'Components/ErrorComponent';
import Loading from 'Components/Loading';
import { successToast } from 'Components/Toasts';
import apiGlobal from 'global/api.global';
import { queryClient } from 'react-query/queryClient';

/**
 * Updates the user-specific notification state.
 * 
 * @param {any} selectedNotificationObject - The selected notification object.
 * @param {string} nextState - The next state to set.
 * @param {any[]} notificationUserStatusObject - List of possible user statuses.
 * @param {any[]} notificationUser - List of user notification objects.
 */
export const HandleNotificationUserState = (
    selectedNotificationObject: any,
    nextState: string,
    notificationUserStatusObject: any,
    notificationUser: any,
    count: number,
    setCount: any
) => {
    if (!selectedNotificationObject) {
        console.error("No notification object selected");
        return;
    }

    const nextStateObject = notificationUserStatusObject.find((state: any) => state.const === nextState);
    if (nextStateObject) {
        selectedNotificationObject.current_user_state = nextStateObject.id;

        const objToBeSent = notificationUser.find((state: any) => state.id === selectedNotificationObject.notification_user_id);
        if (objToBeSent) {
            objToBeSent.current_user_state = selectedNotificationObject.current_user_state;
            apiGlobal.put(`/notification_user/${objToBeSent.id}/`, objToBeSent)
                .then((response) => {
                    if (response.request.status === 200) {
                        successToast("Data saved successfully!");
                        queryClient.invalidateQueries(queryKeyes.notification.NotificationInbox.key);
                        queryClient.invalidateQueries(queryKeyes.notification.NotificationSent.key);
                        queryClient.invalidateQueries(queryKeyes.notification.NotificationDraft.key);
                        queryClient.invalidateQueries(queryKeyes.notification.NotificationArchive.key);
                        setCount(count + 1);
                    }
                })
                .catch((error) => {
                    console.error("Error updating state", error);
                });
        } else {
            console.error("No matching notification user found for update");
        }
    } else {
        console.error("State not found for nextState:", nextState);
    }
};


/**
 * Updates the main notification state.
 * 
 * @param {any} selectedNotificationObject - The selected notification object.
 * @param {string} nextState - The next state to set.
 * @param {any[]} notificationStatusObject - List of possible statuses.
 * @param {any} notificationListObject - The main notification object to update.
 */
export const handleNotificationState = (
    selectedNotificationObject: any,
    nextState: string,
    notificationStatusObject: any,
    notificationListObject: any
) => {
    if (!selectedNotificationObject) {
        console.error("No notification object selected");
        return;
    }

    const nextStateObject = notificationStatusObject.find((state: any) => state.const === nextState);
    if (nextStateObject) {
        selectedNotificationObject.current_state = nextStateObject.id;
        notificationListObject.current_state = selectedNotificationObject.current_state;

        apiGlobal.put(`/notification/${notificationListObject.id}/`, notificationListObject)
            .then((response) => {
                if (response.status === 200) {
                    successToast("Data saved successfully!");
                    queryClient.invalidateQueries(queryKeyes.notification.NotificationInbox.key);
                    queryClient.invalidateQueries(queryKeyes.notification.NotificationSent.key);
                    queryClient.invalidateQueries(queryKeyes.notification.NotificationDraft.key);
                    queryClient.invalidateQueries(queryKeyes.notification.NotificationArchive.key);
                }
            })
            .catch((error) => {
                console.error("Error updating state", error);
            });
    } else {
        console.error("State not found for nextState:", nextState);
    }
};

const Notification = () => {
    // Fetch URL parameters
    const params = useParams()
    let notification: any = {};// Variable to store notification data
    // Define notification states/tabs like Inbox, Sent, Draft etc.
    const notificationStates = [
        {
            label: 'Inbox',
            icon: 'mdi mdi-email-outline me-2',
            url: queryKeyes.notification.NotificationInbox.url(),
            key: queryKeyes.notification.NotificationInbox.key
        },
        {
            label: 'Sent',
            icon: 'mdi mdi-email-check-outline me-2',
            url: queryKeyes.notification.NotificationSent.url(),
            key: queryKeyes.notification.NotificationSent.key,
        },
        {
            label: 'Draft',
            icon: 'mdi mdi-file-outline me-2',
            url: queryKeyes.notification.NotificationDraft.url(),
            key: queryKeyes.notification.NotificationDraft.key
        },
        {
            label: 'Hidden',
            icon: 'mdi mdi-diamond-stone me-2',
            url: queryKeyes.notification.NotificationArchive.url(),
            key: queryKeyes.notification.NotificationArchive.key
        },
    ];

    // Function to get URL based on active tab
    const getNotificationUrl = (label: string) => {
        const notification = notificationStates.filter(notification => {
            return notification.label === label
        })
        return notification[0]?.url ?? null
    }

    // State hooks to manage various states
    const [activeTab, setActiveTab] = useState('Inbox'); // State to track active tab
    const [selectedNotification, setSelectedNotification] = useState(null);// To track the selected notification
    const [compose, setCompose] = useState(false);// To handle if user is composing a notification
    const [pageUrl, setPageUrl] = useState(getNotificationUrl(activeTab));// URL to fetch data based on the active tab
    const [count, setCount] = useState(0);

    const loadKey = () => {
        return notificationStates?.filter((item: any) => item?.label === activeTab)[0]?.key;
    }
    /** Queries start */
    // Fetch notification data based on activeTab and pageUrl using useQuery
    const {
        data: notificationObjects,
        isLoading: notificationLoading,
        isError: notificationError,
        refetch,
    } = useQuery([loadKey()]
        ,// Query key, updates when activeTab or pageUrl changes
        async () => await loadCommonNotificationURL(pageUrl),// Function to fetch data from the API
        {
            enabled: true,
            staleTime: Infinity,
        }
    );

    // Handle tab click to switch between tabs and fetch corresponding data
    function handleTabClick(tabName: string) {
        setActiveTab(tabName); // Update active tab
        setPageUrl(getNotificationUrl(tabName))// Update the page URL based on the selected tab
        setSelectedNotification(null);// Reset selected notification when switching tabs
        setCompose(tabName === 'Compose');// Set compose state based on tab
    }

    // Handle "Back" click from Compose component
    const handleComposeBackClick = () => {
        setCompose(false);
        setActiveTab('Inbox');
    };

    // Handle back button click in NotificationDetails component, clear selected notification
    const handleBackClick = () => {
        setSelectedNotification(null);
    };

    // If the data has loaded and there is no error, check if the URL param `id` is present
    if (!(notificationLoading || notificationError)) {
        if (params.id) {
            // Filter notifications by the id from URL params
            notification = notificationObjects?.results?.filter((notification: any) => notification.notification_id === parseInt(params?.id))
            // if (!notification.length) {
            //     const fetchedNotification = NotificationListHook(parseInt(params.id));
            //     notification = fetchedNotification ? [fetchedNotification] : [{}];
            // }
        }
    }

    /**
  * Fetches the notification user status from the API.
  * The data is used to determine the status of the user for each notification.
  *
  * @returns {object} - Returns an object containing:
  * - data: An array of notification user status objects.
  * - isError: Boolean flag indicating if an error occurred during the fetch.
  * - isLoading: Boolean flag indicating if the data is still being fetched.
  */
    const {
        data: notificationUserStatusObject,
        isError: notificationUserStatusError,
        isLoading: notificationUserStatusLoading
    }: { data: any[], isError: any, isLoading: any } = useQuery(
        [queryKeyes?.notification?.NotificationUserStatus?.key],
        async () => await loadNotificationUserStatus(),
        {
            enabled: true,
            staleTime: Infinity,
        }
    );

    /**
     * Fetches the notification state from the API.
     * This state data provides the status of each notification (e.g., read, unread).
     *
     * @returns {object} - Returns an object containing:
     * - data: An array of notification state objects.
     * - isError: Boolean flag indicating if an error occurred during the fetch.
     * - isLoading: Boolean flag indicating if the data is still being fetched.
     */
    const {
        data: notificationStateObject,
        isError: notificationStateError,
        isLoading: notificationStateLoading
    }: { data: any[], isError: any, isLoading: any } = useQuery(
        [queryKeyes?.notification?.NotificationState?.key],
        async () => await loadNotificationState(),
        {
            enabled: true,
            staleTime: Infinity,
        }
    );

    /**
     * Fetches the notification user data from the API.
     * This data includes user information related to the notifications.
     *
     * @returns {object} - Returns an object containing:
     * - data: An array of notification user objects.
     * - isError: Boolean flag indicating if an error occurred during the fetch.
     * - isLoading: Boolean flag indicating if the data is still being fetched.
     */
    const {
        data: notificationUser,
        isError: notificationUserError,
        isLoading: notificationUserLoading
    }: { data: any[], isError: any, isLoading: any } = useQuery(
        [queryKeyes.notification.NotificationUser.key],
        async () => await loadNotificationUser(),
        {
            staleTime: Infinity,
            enabled: true,
        }
    );

    // Update selected notification whenever params.id changes
    useEffect(() => {
        // if(!(notificationObjects.previous || notificationObjects.next)){
        return setSelectedNotification(notification[0] ?? null)// Set the first notification as selected if exists
        // }
    }, [params.id]);

    return (
        <>
            {(notificationUserStatusLoading || notificationUserLoading ||
                notificationStateLoading
            ) && <Loading message='Loading required data!' />}
            {(notificationUserStatusError || notificationUserError ||
                notificationStateError
            ) && <ErrorComponent message='Unable to load required data!' />}
            {/* Navbar inside Layout */}
            <Layout children={Navbar} />
            <div className="page-content">
                <Container fluid>
                    <h2 className="mb-4">Notifications</h2>
                    {/* <div className="d-flex"> */}
                    <Row>
                        <Col xs={12} md={3}>
                            <Sidebar notificationStates={notificationStates}
                                activeTab={activeTab}
                                handleTabClick={handleTabClick}
                                refetch={refetch} />
                        </Col>
                        <Col xs={12} md={9} className='mt-3 mt-md-0'>
                            <Card className=" shadow-sm mb-4 ">
                                {compose ? (
                                    <Compose handleBackClick={handleComposeBackClick} />
                                ) :
                                    <>
                                        <NotificationList handleNotificationClick={setSelectedNotification}
                                            notificationObjects={notificationObjects}
                                            notificationLoading={notificationLoading}
                                            notificationError={notificationError}
                                            selectedNotification={selectedNotification}
                                            handleBackClick={handleBackClick}
                                            notificationUserStatusObject={notificationUserStatusObject}
                                            notificationUser={notificationUser}
                                            notificationStateObject={notificationStateObject}
                                            activeTab={activeTab}
                                            count={count}
                                            setCount={setCount}
                                            refetch={refetch}
                                        />
                                        <Row className='p-2 m-1'>
                                            <Col xs="7">Showing {notificationObjects?.results?.length ?? 0} of {notificationObjects?.count}</Col>
                                            <Col xs="5">
                                                <div className="btn-group float-end">
                                                    <Button
                                                        type="button"
                                                        color="success"
                                                        size="sm"
                                                        className="waves-effect"
                                                        disabled={!(notificationObjects?.previous)}
                                                        onClick={() => {
                                                            if (notificationObjects?.previous)
                                                                setPageUrl(notificationObjects?.previous)
                                                        }}
                                                    >
                                                        <i className="fa fa-chevron-left" />
                                                    </Button>
                                                    <Button
                                                        type="button"
                                                        color="success"
                                                        size="sm"
                                                        className="waves-effect"
                                                        disabled={!(notificationObjects?.next)}
                                                        onClick={() => {
                                                            if (notificationObjects?.next)
                                                                setPageUrl(notificationObjects?.next)
                                                        }}
                                                    >
                                                        <i className="fa fa-chevron-right" />
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </Card>
                        </Col>
                    </Row>
                    {/* </div> */}
                </Container>
            </div>
        </>
    );
};

export default Notification;