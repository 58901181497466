import React from 'react';
import { queryClient } from 'react-query/queryClient';
import { Card, Button } from 'reactstrap';
import { NotificationActiveTab } from 'shared/constants';

interface SidebarProps {
    activeTab: string;
    handleTabClick: (tabName: string) => void;
    notificationStates: any;
    refetch:()=>any;
}

const Sidebar = ({ activeTab, handleTabClick, notificationStates, refetch }: SidebarProps) => {
    return (
        <Card className="p-3 notification-sidebar h-auto">
            <ul className="list-unstyled mail-list">
                {/* Compose Button */}
                <li className="mb-3">
                    <Button
                        type="button"
                        color="primary"
                        className="w-100"
                        onClick={() => handleTabClick('Compose')}
                    >
                        Compose
                    </Button>
                </li>
                {/* Other Sidebar Items */}
                {notificationStates.map((item: any, index: number) => (
                    <li className="mb-3" key={index}>
                        <div
                            className={`d-flex align-items-center p-2 cursor-pointer cursor-arrow ${activeTab === item.label ? 'bg-light rounded' : ''}`}
                            onClick={() => { 
                                handleTabClick(item.label);
                                queryClient.invalidateQueries(item.key);
                                refetch();
                            }}
                        >
                            <i className={item.icon}></i>
                            {(item.label === NotificationActiveTab.HIDDEN) ? <span>Archived</span>
                                : <span>{item.label}</span>}
                        </div>
                    </li>
                ))}
            </ul>
        </Card>
    );
};

export default Sidebar;