import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { Row } from "reactstrap";

class VoyagewiseCompliance extends Component<any> {
    Chart = () => {
        return {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                top: '5%',
                left: 'center'
            },
            series: [
                {
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: 40,
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        { value: 1048, name: 'Not complying' },
                        { value: 735, name: 'Complying' },
                    ]
                }
            ]
        };
    }

    render() {
        return (
            <>
                <Row className="mt-3">
                    <ReactEcharts style={{ height: "350px" }} option={this.Chart()} />
                </Row>
            </>
        );
    }
}

export default VoyagewiseCompliance;
