import ErrorComponent from 'Components/ErrorComponent';
import Loading from 'Components/Loading';
import { successToast, errorToast } from 'Components/Toasts';
import { calculateTimeDurationBetwnReports, errResponse, machineryEnabled } from 'GenericForms/Helper';
import apiGlobal from 'global/api.global';
import env from 'environment/env';
import React, { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { queryClient } from 'react-query/queryClient';
import { FuelSubTypes, SpecialOpsConstant } from 'shared/constants';
import { queryKeyes } from 'shared/queryKeys';
import { vesselFuel, loadTimePeriod, loadAuxEngineMaxSFOC, loadBatchConsumptionObject, loadSpecialOperation, loadReeferContainer4hrObject, loadReeferContainerEnergyObject, loadReeferContainerFuelObject, loadCargoHeating4hrObject, loadCargoHeatingEnergyObject, loadCargoHeatingFuelObject, loadCargoReliquification4hrObject, loadCargoReliquificationEnergyObject, loadCargoReliquificationFuelObject, loadTankCleaning4hrObject, loadTankCleaningEnergyObject, loadTankCleaningFuelObject, loadCargoDischarging4hrObject, loadCargoDischargingEnergyObject, loadCargoDischargingFuelObject, loadTankCleaningFuel, loadTankCleaningWithkWh, loadCargoDischargingWithkWh, loadCargoDischargingFuel, loadCargoHeatingWithkWh, loadCargoHeatingFuel, loadCargoReliquificationWithkWh, loadCargoReliquificationFuel, loadReeferContainerWithkWh, loadReeferContainerFuel, loadSpOpFuelSubTypeObject, loadCargoCooling4hrObject, loadCargoCoolingWithkWh, loadCargoCoolingEnergyObject, loadCargoCoolingFuel, loadCargoCoolingFuelObject, loadFuelConsumptionObject, loadFuelSettings, loadFCElectricalReeferData } from 'VesselMaster/vesselMaster.hooks';
import * as Yup from 'yup';
import ReportDetailsHeader from 'Components/ReportDetailsHeader';
import { Card, CardHeader, Row, Col, Form, CardBody, Button, CardFooter, Input, Label, Modal } from 'reactstrap';
import SpOpFuelBatchConsumption from './SpOpFuelBatchConsumption';
import { Formik, FieldArray, Field, FieldProps } from 'formik';
import SpOpFuelConsumptionComponent from './SpOpFuelConsumptionComponent';
import { TooltipMsg } from 'Components/ToolTipMessage';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import FourHrTableComponent from './FourHrTableComponent';
import SpOpEnergyConsumptionComponent from './SpOpEnergyConsumptionComponent';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import ToolTip from 'Components/ToolTip';

const SpecialOperationComponent = ({
    VesselID,
    VoyageID,
    ReportID,
    Operation,
    lastRecord,
    record,
    toggleDynamicTabs,
    previousTabIndex,
    tabsIdList,
    tabsList,
    toggleTab,
    energyField,
    FourHrTblHeader,
    tab
}: any) => {
    /** State varibles start */
    const [fuelQueryObj, setFuelQueryObj] = useState<any>({
        variable: null,
        loadVariable: null
    });
    const [energyQueryObj, setEnergyQueryObj] = useState<any>({
        variable: null,
        loadVariable: null
    });
    const [fourHrQueryObj, setFourHrQueryObj] = useState<any>({
        variable: null,
        loadVariable: null
    });
    const [fuelMachineQueryObj, setFuelMachineQueryObj] = useState<any>({
        variable: null,
        loadVariable: null
    });
    const [energyMachineQueryObj, setEnergyMachineQueryObj] = useState<any>({
        variable: null,
        loadVariable: null
    });
    const [spOpConstant, setSpOpConstant] = useState(0);
    const [totalConsumption, setTotalConsumption] = useState<any>(null);
    const [fuelConsumed, setFuelConsumed] = useState<any>({});
    const [withkWhMeter, setWithkWhMeter] = useState<Array<any>>([]);
    const [withoutkWhMeter, setWithoutkWhMeter] = useState<Array<any>>([]);
    const [applicableTimePeriod, setApplicableTimePeriod] = useState<Array<any>>([]);
    const [modal_backdrop, setmodal_backdrop] = useState(false);
    const [fuelSubTypeConsumption, setFuelSubTypeConsumption] = useState(false);
    const [modalMachinery, setModalMachinery] = useState<any>({});
    const [modalIndex, setModalIndex] = useState<number>(null);
    /** State variables end */

    /** Queries */
    /** Fetch Vessel distinct fuel type */
    const { data: fuelTypes, isLoading: fuelTypesLoading, isError: fuelTypesError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.fuel.key, VesselID],
            async () => {
                return await vesselFuel(VesselID);
            },
            { staleTime: Infinity }
        );
    /** Time periods for 4 hours table */
    const { data: TimePeriod, isLoading: TimePeriodLoading, isError: TimePeriodError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.TimePeriodObject.key],
            async () => {
                return await loadTimePeriod();
            },
            { staleTime: Infinity }
        )
    /** Max SFOC of vessel's auxiliary engines */
    const { data: AuxEngineMaxSFOC, isLoading: AuxEngineMaxSFOCLoading, isError: AuxEngineMaxSFOCError } = useQuery(
        [queryKeyes.vessel.AuxEngineMaxSFOC.key, VesselID],
        async () => {
            return await loadAuxEngineMaxSFOC(VesselID);
        }, { staleTime: Infinity }
    );
    /** Batch Consumption Object used for edit */
    const { data: BatchConsumptionObject, isLoading: BatchConsumptionObjectLoading, isError: BatchConsumptionObjectError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.BatchConsumptionObject.key, VesselID, ReportID],
            async () => {
                return await loadBatchConsumptionObject(VesselID, ReportID);
            }, { staleTime: Infinity }
        );
    /** Special operation object from master */
    const { data: SpecialOpsObject, isLoading: SpecialOpsObjectLoading, isError: SpecialOpsObjectError }:
        { data: any, isLoading: any, isError: any } = useQuery(
            [queryKeyes.masters.SpecialOperationMaster.key, spOpConstant],
            async () => {
                return await loadSpecialOperation(spOpConstant);
            }, { staleTime: Infinity }
        );
    /** Fuel Consumption Object used for edit */
    const { data: FuelConsumptionObject, isLoading: FuelConsumptionObjectLoading, isError: FuelConsumptionObjectError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.FuelConsumptionObject.key, VesselID, ReportID],
            async () => {
                return await loadFuelConsumptionObject(VesselID, ReportID);
            }, { staleTime: Infinity }
        );
    /** Applicable fuel consumers */
    const { data: machineryFuelGroup, isLoading: machineryFuelGroupLoading, isError: machineryFuelGroupError } = useQuery(
        [fuelMachineQueryObj.variable],
        async () => {
            return await fuelMachineQueryObj.loadVariable;
        }, { staleTime: Infinity }
    );
    /** Applicable energy consumers */
    const { data: machineryEnergyGroup, isLoading: machineryEnergyGroupLoading, isError: machineryEnergyGroupError } = useQuery(
        [energyMachineQueryObj.variable],
        async () => {
            return await energyMachineQueryObj.loadVariable;
        }, { staleTime: Infinity }
    );
    /** Special Operation fuel Object used for edit */
    const { data: FuelObject, isLoading: FuelObjectLoading } = useQuery(
        [fuelQueryObj.variable],
        async () => {
            return await fuelQueryObj.loadVariable;
        }, { staleTime: Infinity }
    );
    /** Special Operation energy Object used for edit */
    const { data: EnergyObject, isLoading: EnergyObjectLoading } = useQuery(
        [energyQueryObj.variable],
        async () => {
            return await energyQueryObj.loadVariable;
        }, { staleTime: Infinity }
    );
    /** Special Operation four hour Object used for edit */
    const { data: FourHrObject, isLoading: FourHrObjectLoading } = useQuery(
        [fourHrQueryObj.variable],
        async () => {
            return await fourHrQueryObj.loadVariable;
        }, { staleTime: Infinity }
    );
    /** Special Operation fuel sub type object used for edit */
    const { data: FuelSubTypeObject, isLoading: FuelSubTypeObjectLoading }:
        { data: any, isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.SpOpFuelSubTypeObject.key, VesselID, ReportID, spOpConstant],
            async () => {
                return await loadSpOpFuelSubTypeObject(VesselID, ReportID, spOpConstant);
            }, { staleTime: Infinity }
        );
    /** Fetch Vessel fuel settings */
    const { data: fuelSettings, isLoading: fuelSettingsLoading, isError: fuelSettingsError } = useQuery(
        [queryKeyes.vessel.FuelSettings.key, VesselID],
        async () => {
            return await loadFuelSettings(VesselID);
        }, { staleTime: Infinity }
    );
    /** Reefer container FC Electrical Object */
    const { data: FCElectrical, isLoading: FCElectricalLoading } = useQuery(
        [queryKeyes.vessel.FCElectricalReeferObject.key, VesselID, ReportID],
        async () => {
            return await loadFCElectricalReeferData(VesselID, ReportID);
        }, { staleTime: Infinity }
    );
    /** Queries end */

    /** Assign values to formik's fuelConsumption initial object */
    const getinitialFuelConsumption = useCallback(() => {
        let arr: any[] = [];
        if (FuelObject && FuelObject?.length > 0) {
            return FuelObject;
        } else if (arr !== undefined &&
            machineryFuelGroup !== undefined &&
            fuelTypes !== undefined &&
            arr.length <= machineryFuelGroup?.length * fuelTypes?.length) {
            machineryFuelGroup.flatMap((machine: any) => {
                fuelTypes.map((fuel: any) => {
                    arr.push({
                        total_fuel_consumption: 0,
                        special_operation: (SpecialOpsObject && SpecialOpsObject[0]?.id) ? SpecialOpsObject[0]?.id : null,
                        vessel: VesselID,
                        voyage_information: VoyageID,
                        vessel_reporting_information: ReportID,
                        vessel_machinery_fc_group: machine.vessel_machinery_fc_group,
                        vessel_fuel: fuel.fuel_type
                    });
                    return fuel;
                });
                return machine;
            });
            return arr;
        }
    }, [FuelObject, machineryFuelGroup, fuelTypes, SpecialOpsObject, VesselID, VoyageID, ReportID])

    /** Assign values to formik's fuel sub type consumption initial object */
    const getInitialFuelSubTypeConsumption = useCallback(() => {
        let Arr: any[] = [];
        if (FuelSubTypeObject && FuelSubTypeObject?.length > 0) {
            return FuelSubTypeObject;
        } else {
            BatchConsumptionObject && machineryFuelGroup &&
                machineryFuelGroup.forEach((machine: any) => {
                    BatchConsumptionObject
                        .filter((item: any) => item.fuel_consumption > 0 &&
                            (item?.fuel_sub_type === FuelSubTypes.HFO || item?.fuel_sub_type === FuelSubTypes.LFO))
                        .forEach((batch: any) => {
                            Arr.push({
                                fuel_consumption: null,
                                special_operation_fuel: (FuelObject && FuelObject?.filter((item: any) =>
                                    item.vessel_machinery_fc_group === machine.vessel_machinery_fc_group &&
                                    item.vessel_fuel === batch?.fuel_name)[0]?.id) ?? null,
                                special_operation: (SpecialOpsObject && SpecialOpsObject[0]?.id) ? SpecialOpsObject[0]?.id : null,
                                vessel_machinery_fc_group: machine.vessel_machinery_fc_group,
                                vessel_machinery_fc_group_name: machine.vessel_machinery_fc_group_name,
                                fuel_sub_type: batch?.fuel_sub_type,
                                fuel_consumption_batch: batch?.fuel_batch_name,
                                fuel_type: batch?.fuel_name,
                                vessel: VesselID,
                                voyage_information: VoyageID,
                                vessel_reporting_information: ReportID
                            })
                        })
                })
        }
        return Arr;
    }, [FuelSubTypeObject, machineryFuelGroup, BatchConsumptionObject, FuelObject, SpecialOpsObject, VesselID, VoyageID, ReportID])

    /** Assign values to formik's energy consumer's with functional kWh meter initial object */
    const getInitialFunctionalkWhMeterConsumption = useCallback(() => {
        let obj: any[] = [];
        if (EnergyObject && EnergyObject?.length > 0) {
            return EnergyObject;
        } else if (obj !== undefined &&
            withkWhMeter !== undefined &&
            obj.length <= withkWhMeter.length) {
            withkWhMeter.map((machine: any) => {
                obj.push({
                    is_kwh_meter_not_functional: false,
                    energy_consumed: 0,
                    equivalent_fuel_consumption: 0,
                    special_operation: (SpecialOpsObject && SpecialOpsObject[0]?.id) ? SpecialOpsObject[0]?.id : null,
                    vessel: VesselID,
                    voyage_information: VoyageID,
                    vessel_reporting_information: ReportID,
                    vessel_machinery_ec_group: machine.vessel_machinery_ec_group,
                    vessel_machinery_ec_group_name: machine.vessel_machinery_ec_group_name
                });
                return machine;
            });
            return obj;
        }
    }, [EnergyObject, withkWhMeter, SpecialOpsObject, VesselID, VoyageID, ReportID])

    /** Assign values to formik's energy consumer's with non functional kWh meter initial object */
    const getInitialNonFunctionalkWhMeterConsumption = useCallback(() => {
        let arr: any[] = [];
        if (SpecialOpsObject && SpecialOpsObject[0]?.constant === SpecialOpsConstant.REEFER_CONTAINER) {
            if (FCElectrical && FCElectrical?.length > 0) {
                return FCElectrical;
            } else {
                arr.push({
                    reefer_days_at_sea: 'No data available',
                    reefer_days_in_port: 'No data available',
                    equivalent_fuel_consumption: 'No data available',
                    fc_electrical_reefer: 'No data available',
                })
                return arr;
            }
        } else if ((FourHrObject && FourHrObject?.length > 0) && (EnergyObject && EnergyObject?.length > 0)) {
            EnergyObject.forEach((machine: any) => {
                FourHrObject?.filter((item: any) =>
                    item.vessel_machinery_name === machine.vessel_machinery_ec_group)?.forEach((obj: any) => {
                        arr.push(obj);
                    });
            })
            return arr;
        } else if (
            arr !== undefined &&
            withkWhMeter !== undefined &&
            applicableTimePeriod?.length > 0 && applicableTimePeriod[0].id > 0 &&
            arr.length <= withkWhMeter.length) {
            withkWhMeter.flatMap((machine: any) => {
                applicableTimePeriod.map((time: any) => {
                    arr.push({
                        hours_of_consumption: 0,
                        v: 0,
                        i: 0,
                        pf: 0,
                        w: 0,
                        special_operation: (SpecialOpsObject && SpecialOpsObject[0]?.id) ? SpecialOpsObject[0]?.id : null,
                        vessel_machinery_name: machine.vessel_machinery_ec_group,
                        vessel: VesselID,
                        voyage_information: VoyageID,
                        vessel_reporting_information: ReportID,
                        period: time.id,
                        machine_name: machine.vessel_machinery_ec_group_name,
                        time_period: time.period
                    })
                    return time;
                })
                return machine;
            })
            return arr
        }
    }, [EnergyObject, FourHrObject, withkWhMeter, applicableTimePeriod, SpecialOpsObject, VesselID, VoyageID, ReportID, FCElectrical])

    /** Assign values to formik's energy consumer's without kWh meter initial object */
    const getInitialWithoutkWhMeterConsumption = useCallback(() => {
        let obj: any[] = [];
        if (FourHrObject && FourHrObject?.length > 0) {
            withoutkWhMeter.forEach((machine: any) => {
                obj.push(FourHrObject?.filter((item: any) =>
                    item.vessel_machinery_name === machine.vessel_machinery_ec_group)[0])
            })
            return obj;
        } else if (obj !== undefined &&
            applicableTimePeriod?.length > 0 && applicableTimePeriod[0].id > 0 &&
            obj.length <= withoutkWhMeter.length) {
            withoutkWhMeter.flatMap((machine: any) => {
                applicableTimePeriod.map((time: any) => {
                    obj.push({
                        hours_of_consumption: 0,
                        v: 0,
                        i: 0,
                        pf: 0,
                        w: 0,
                        special_operation: (SpecialOpsObject && SpecialOpsObject[0]?.id) ? SpecialOpsObject[0]?.id : null,
                        vessel_machinery_name: machine.vessel_machinery_ec_group,
                        vessel: VesselID,
                        voyage_information: VoyageID,
                        vessel_reporting_information: ReportID,
                        period: time.id,
                        machine_name: machine.vessel_machinery_ec_group_name,
                        time_period: time.period
                    });
                    return time;
                });
                return machine;
            });
            return obj;
        }
    }, [FourHrObject, applicableTimePeriod, withoutkWhMeter, SpecialOpsObject, VesselID, VoyageID, ReportID])

    /** useEffect start */
    useEffect(() => {
        const loadQueryData = () => {
            let fuelObj: any = {
                variable: null,
                loadVariable: null
            }
            let energyObj: any = {
                variable: null,
                loadVariable: null
            }
            let fourHrObj: any = {
                variable: null,
                loadVariable: null
            }
            let fuelMachineObj: any = {
                variable: null,
                loadVariable: null
            }
            let energyMachineObj: any = {
                variable: null,
                loadVariable: null
            }
            switch (Operation) {
                case "Cargo Cooling":
                    /** for fuel consumer machinery group query */
                    fuelMachineObj.variable = (queryKeyes.vessel.CargoCoolingFuelMachineries.key);
                    fuelMachineObj.loadVariable = loadCargoCoolingFuel(VesselID);
                    setFuelMachineQueryObj(fuelMachineObj);
                    /** for energy consumer machinery group query */
                    energyMachineObj.variable = (queryKeyes.vessel.CargoCoolingWithkWhMachineries.key);
                    energyMachineObj.loadVariable = loadCargoCoolingWithkWh(VesselID);
                    setEnergyMachineQueryObj(energyMachineObj);
                    /** for fuel consumption object's query */
                    fuelObj.variable = (queryKeyes.vessel.CargoCoolingFuelObject.key);
                    fuelObj.loadVariable = loadCargoCoolingFuelObject(VesselID, ReportID);
                    setFuelQueryObj(fuelObj);
                    /** for energy consumption with functional kWh meter object's query */
                    energyObj.variable = (queryKeyes.vessel.CargoCoolingEnergyObject.key);
                    energyObj.loadVariable = loadCargoCoolingEnergyObject(VesselID, ReportID);
                    setEnergyQueryObj(energyObj);
                    /** for energy consumption without & non-functional kWh meter object's query */
                    fourHrObj.variable = (queryKeyes.vessel.CargoCooling4hrObject.key);
                    fourHrObj.loadVariable = loadCargoCooling4hrObject(VesselID, ReportID);
                    setFourHrQueryObj(fourHrObj);
                    /** set Special Operation constant */
                    setSpOpConstant(SpecialOpsConstant.CARGO_COOLING);
                    break;
                case "Cargo Discharging":
                    /** for fuel consumer machinery group query */
                    fuelMachineObj.variable = (queryKeyes.vessel.CargoDischargingFuelMachineries.key);
                    fuelMachineObj.loadVariable = loadCargoDischargingFuel(VesselID);
                    setFuelMachineQueryObj(fuelMachineObj);
                    /** for energy consumer machinery group query */
                    energyMachineObj.variable = (queryKeyes.vessel.CargoDischargingWithkWhMachineries.key);
                    energyMachineObj.loadVariable = loadCargoDischargingWithkWh(VesselID);
                    setEnergyMachineQueryObj(energyMachineObj);
                    /** for fuel consumption object's query */
                    fuelObj.variable = (queryKeyes.vessel.CargoDischargingFuelObject.key);
                    fuelObj.loadVariable = loadCargoDischargingFuelObject(VesselID, ReportID);
                    setFuelQueryObj(fuelObj);
                    /** for energy consumption with functional kWh meter object's query */
                    energyObj.variable = (queryKeyes.vessel.CargoDischargingEnergyObject.key);;
                    energyObj.loadVariable = loadCargoDischargingEnergyObject(VesselID, ReportID);
                    setEnergyQueryObj(energyObj);
                    /** for energy consumption without & non-functional kWh meter object's query */
                    fourHrObj.variable = (queryKeyes.vessel.CargoDischarging4hrObject.key);
                    fourHrObj.loadVariable = loadCargoDischarging4hrObject(VesselID, ReportID);
                    setFourHrQueryObj(fourHrObj);
                    /** set Special Operation constant */
                    setSpOpConstant(SpecialOpsConstant.CARGO_DISCHARGING);
                    break;
                case "Cargo Heating":
                    /** for fuel consumer machinery group query */
                    fuelMachineObj.variable = (queryKeyes.vessel.CargoHeatingFuelMachineries.key);
                    fuelMachineObj.loadVariable = loadCargoHeatingFuel(VesselID);
                    setFuelMachineQueryObj(fuelMachineObj);
                    /** for energy consumer machinery group query */
                    energyMachineObj.variable = (queryKeyes.vessel.CargoHeatingWithkWhMachineries.key);
                    energyMachineObj.loadVariable = loadCargoHeatingWithkWh(VesselID);
                    setEnergyMachineQueryObj(energyMachineObj);
                    /** for fuel consumption object's query */
                    fuelObj.variable = (queryKeyes.vessel.CargoHeatingFuelObject.key);
                    fuelObj.loadVariable = loadCargoHeatingFuelObject(VesselID, ReportID);
                    setFuelQueryObj(fuelObj);
                    /** for energy consumption with functional kWh meter object's query */
                    energyObj.variable = (queryKeyes.vessel.CargoHeatingEnergyObject.key);
                    energyObj.loadVariable = loadCargoHeatingEnergyObject(VesselID, ReportID);
                    setEnergyQueryObj(energyObj);
                    /** for energy consumption without & non-functional kWh meter object's query */
                    fourHrObj.variable = (queryKeyes.vessel.CargoHeating4hrObject.key);
                    fourHrObj.loadVariable = loadCargoHeating4hrObject(VesselID, ReportID);
                    setFourHrQueryObj(fourHrObj);
                    /** set Special Operation constant */
                    setSpOpConstant(SpecialOpsConstant.CARGO_HEATING);
                    break;
                case "Cargo Reliquification":
                    /** for fuel consumer machinery group query */
                    fuelMachineObj.variable = (queryKeyes.vessel.CargoReliquificationFuelMachineries.key);
                    fuelMachineObj.loadVariable = loadCargoReliquificationFuel(VesselID);
                    setFuelMachineQueryObj(fuelMachineObj);
                    /** for energy consumer machinery group query */
                    energyMachineObj.variable = (queryKeyes.vessel.CargoReliquificationWithkWhMachineries.key);
                    energyMachineObj.loadVariable = loadCargoReliquificationWithkWh(VesselID);
                    setEnergyMachineQueryObj(energyMachineObj);
                    /** for fuel consumption object's query */
                    fuelObj.variable = (queryKeyes.vessel.CargoReliquificationFuelObject.key);
                    fuelObj.loadVariable = loadCargoReliquificationFuelObject(VesselID, ReportID);
                    setFuelQueryObj(fuelObj);
                    /** for energy consumption with functional kWh meter object's query */
                    energyObj.variable = (queryKeyes.vessel.CargoReliquificationEnergyObject.key);
                    energyObj.loadVariable = loadCargoReliquificationEnergyObject(VesselID, ReportID);
                    setEnergyQueryObj(energyObj);
                    /** for energy consumption without & non-functional kWh meter object's query */
                    fourHrObj.variable = (queryKeyes.vessel.CargoReliquification4hrObject.key);
                    fourHrObj.loadVariable = loadCargoReliquification4hrObject(VesselID, ReportID);
                    setFourHrQueryObj(fourHrObj);
                    /** set Special Operation constant */
                    setSpOpConstant(SpecialOpsConstant.CARGO_RELIQUIFICATION);
                    break;
                case "Reefer Container":
                    /** for fuel consumer machinery group query */
                    fuelMachineObj.variable = (queryKeyes.vessel.ReeferContainerFuelMachineries.key);
                    fuelMachineObj.loadVariable = loadReeferContainerFuel(VesselID);
                    setFuelMachineQueryObj(fuelMachineObj);
                    /** for energy consumer machinery group query */
                    energyMachineObj.variable = (queryKeyes.vessel.ReeferContainerWithkWhMachineries.key);
                    energyMachineObj.loadVariable = loadReeferContainerWithkWh(VesselID);
                    setEnergyMachineQueryObj(energyMachineObj);
                    /** for fuel consumption object's query */
                    fuelObj.variable = (queryKeyes.vessel.ReeferContainerFuelObject.key);
                    fuelObj.loadVariable = loadReeferContainerFuelObject(VesselID, ReportID);
                    setFuelQueryObj(fuelObj);
                    /** for energy consumption with functional kWh meter object's query */
                    energyObj.variable = (queryKeyes.vessel.ReeferContainerEnergyObject.key);
                    energyObj.loadVariable = loadReeferContainerEnergyObject(VesselID, ReportID);
                    setEnergyQueryObj(energyObj);
                    /** for energy consumption without & non-functional kWh meter object's query */
                    fourHrObj.variable = (queryKeyes.vessel.ReeferContainer4hrObject.key);
                    fourHrObj.loadVariable = loadReeferContainer4hrObject(VesselID, ReportID);
                    setFourHrQueryObj(fourHrObj);
                    /** set Special Operation constant */
                    setSpOpConstant(SpecialOpsConstant.REEFER_CONTAINER);
                    break;
                case "Tank Cleaning":
                    /** for fuel consumer machinery group query */
                    fuelMachineObj.variable = (queryKeyes.vessel.TankCleaningFuelMachineries.key);
                    fuelMachineObj.loadVariable = loadTankCleaningFuel(VesselID);
                    setFuelMachineQueryObj(fuelMachineObj);
                    /** for energy consumer machinery group query */
                    energyMachineObj.variable = (queryKeyes.vessel.TankCleaningWithkWhMachineries.key);
                    energyMachineObj.loadVariable = loadTankCleaningWithkWh(VesselID);
                    setEnergyMachineQueryObj(energyMachineObj);
                    /** for fuel consumption object's query */
                    fuelObj.variable = (queryKeyes.vessel.TankCleaningFuelObject.key);
                    fuelObj.loadVariable = loadTankCleaningFuelObject(VesselID, ReportID);
                    setFuelQueryObj(fuelObj);
                    /** for energy consumption with functional kWh meter object's query */
                    energyObj.variable = (queryKeyes.vessel.TankCleaningEnergyObject.key);
                    energyObj.loadVariable = loadTankCleaningEnergyObject(VesselID, ReportID);
                    setEnergyQueryObj(energyObj);
                    /** for energy consumption without & non-functional kWh meter object's query */
                    fourHrObj.variable = (queryKeyes.vessel.TankCleaning4hrObject.key);
                    fourHrObj.loadVariable = loadTankCleaning4hrObject(VesselID, ReportID);
                    setFourHrQueryObj(fourHrObj);
                    /** set Special Operation constant */
                    setSpOpConstant(SpecialOpsConstant.TANK_CLEANING);
                    break;
            }
        }; loadQueryData();
    }, [Operation, VesselID, ReportID])
    /** Distinguish energy consumers based on kWh meter's presence */
    useEffect(() => {
        if (machineryEnergyGroup?.length > 0 && machineryEnergyGroup[0].id > 0) {
            setWithkWhMeter(machineryEnergyGroup.filter((item: any) => item[energyField] === true));
            setWithoutkWhMeter(machineryEnergyGroup.filter((item: any) => item[energyField] === false));
        }
    }, [machineryEnergyGroup, VesselID, energyField])
    /** Show applicable time periods */
    useEffect(() => {
        let array: any[] = [];
        let timeDurationHr = calculateTimeDurationBetwnReports(lastRecord?.reporting_time_utc, record?.reporting_time_utc) / 60;
        let timeDuration = Math.floor(Math.abs(timeDurationHr / 4))
        if (record && lastRecord && timeDuration && TimePeriod && TimePeriod?.length > 0) {
            for (let i = 0; i <= timeDuration; i++) {
                let index;
                if (i <= 5) {
                    index = i;
                } else {
                    index = (i - 6) % 6;
                }
                const lastRecordDateTime = new Date(lastRecord.reporting_time_utc);
                const currRecordDateTime = new Date(record.reporting_time_utc);
                const lastRecordDate = new Date(lastRecord.reporting_time_utc.toString().substring(0, 10));
                const currRecordDate = new Date(record?.reporting_time_utc.toString().substring(0, 10));
                const lastRecordPeriodEnd = new Date(`${lastRecord?.reporting_time_utc?.toString()?.substring(0, 11)}${TimePeriod[index]?.period?.toString()?.substring(5, 7)}:${TimePeriod[index]?.period?.toString()?.substring(7, 9)}:00Z`);
                const currRecordPeriodEnd = new Date(`${record?.reporting_time_utc?.toString()?.substring(0, 11)}${TimePeriod[index]?.period?.toString()?.substring(5, 7)}:${TimePeriod[index]?.period?.toString()?.substring(7, 9)}:00Z`);
                if (lastRecordDate.toISOString() !== currRecordDate.toISOString()) {
                    if (TimePeriod[index].id <= 3) {
                        if (i <= 3) {
                            if (lastRecordPeriodEnd.toISOString() >= lastRecordDateTime.toISOString()) {
                                array.push(TimePeriod[index]);
                            }
                        } else {
                            if (currRecordDateTime.toISOString() >= currRecordPeriodEnd.toISOString()) {
                                array.push(TimePeriod[index]);
                            }
                        }
                    } else {
                        if (currRecordDateTime.toISOString() >= currRecordPeriodEnd.toISOString()) {
                            array.push(TimePeriod[index]);
                        }
                    }
                } else {
                    if (TimePeriod[index].id <= 3) {
                        if (currRecordPeriodEnd.toISOString() <= currRecordDateTime.toISOString()) {
                            array.push(TimePeriod[index]);
                        }
                    }
                }
            }
        }
        setApplicableTimePeriod(array);
    }, [lastRecord, record, TimePeriod])
    /** useEffect end */

    useEffect(() => {
        SpecialOperationFormik.initialValues.fuelConsumption = getinitialFuelConsumption();
    }, [getinitialFuelConsumption, SpecialOpsObject,
        FuelConsumptionObject, fuelSettings])
    useEffect(() => {
        SpecialOperationFormik.initialValues.fuelSubTypeConsumption = getInitialFuelSubTypeConsumption();
    }, [machineryFuelGroup, BatchConsumptionObject, FuelSubTypeObject, getInitialFuelSubTypeConsumption,
        SpecialOpsObject
    ])
    useEffect(() => {
        SpecialOperationFormik.initialValues.withkWhMeter.functional = getInitialFunctionalkWhMeterConsumption();
    }, [withkWhMeter, EnergyObject, SpecialOpsObject, getInitialFunctionalkWhMeterConsumption])
    useEffect(() => {
        SpecialOperationFormik.initialValues.withkWhMeter.nonfunctional = getInitialNonFunctionalkWhMeterConsumption();
    }, [withkWhMeter, applicableTimePeriod, EnergyObject, FourHrObject, FCElectrical, SpecialOpsObject, getInitialNonFunctionalkWhMeterConsumption])
    useEffect(() => {
        SpecialOperationFormik.initialValues.withoutkWhMeter = getInitialWithoutkWhMeterConsumption();
    }, [applicableTimePeriod, withoutkWhMeter, FourHrObject, SpecialOpsObject,
        getInitialWithoutkWhMeterConsumption
    ])

    /** Special Operation's formik object */
    const SpecialOperationFormik = {
        initialValues: {
            fuelConsumption: getinitialFuelConsumption(),
            fuelSubTypeConsumption: getInitialFuelSubTypeConsumption(),
            withkWhMeter: {
                functional: getInitialFunctionalkWhMeterConsumption(),
                nonfunctional: getInitialNonFunctionalkWhMeterConsumption(),
            },
            withoutkWhMeter: getInitialWithoutkWhMeterConsumption(),
        },
        validationScehma: Yup.object({
            fuelConsumption: Yup.array(
                Yup.object({}).test(function (value: any) {
                    const fuelConsumption: any = (this.parent) ? this.parent : [];
                    const index = fuelConsumption?.indexOf(value);
                    for (const item of FuelConsumptionObject) {
                        if (value.vessel_machinery_fc_group === item.vessel_machinery_fc_group &&
                            value.vessel_fuel === item.vessel_fuel) {
                            if (parseFloat(value.total_fuel_consumption) > item.fuel_consumed) {
                                return new Yup.ValidationError(
                                    `Fuel consumption should be equal to or less than that mentioned in the fuel consumption form`,
                                    undefined,
                                    `fuelConsumption.${index}.total_fuel_consumption`
                                );
                            }
                        }
                    }
                    return true;
                })
            ),
            fuelSubTypeConsumption: Yup.array(
                Yup.object({}).test(function (value: any) {
                    const fuelSubTypeConsumption: any = (this.parent) ? this.parent : [];
                    const index = fuelSubTypeConsumption?.indexOf(value);
                    let sum: number = 0;
                    fuelSubTypeConsumption?.filter((item: any) =>
                        item.vessel_machinery_fc_group === fuelConsumed.vessel_machinery_fc_group &&
                        (item.fuel_sub_type === FuelSubTypes.HFO || item.fuel_sub_type === FuelSubTypes.LFO))
                        .forEach((batch: any, indx: number) => {
                            if (batch.fuel_consumption === null) {
                                batch.fuel_consumption = 0;
                            }
                            sum += parseFloat(batch.fuel_consumption);
                        })
                    if (totalConsumption !== null) {
                        if (sum !== parseFloat(totalConsumption)) {
                            throw new Yup.ValidationError(
                                `The sum of batch consumptions should be ${totalConsumption} mt`,
                                undefined,
                                `fuelSubTypeConsumption.${index}.fuel_consumption`
                            )
                        }
                        return true;
                    }
                    return true;
                })
            ),
            withkWhMeter: Yup.object({
                nonfunctional: Yup.array(
                    Yup.object({
                        hours_of_consumption: Yup.number().max(4, commonValidationMessages.max4),
                    })
                )
            }),
            withoutkWhMeter: Yup.array(
                Yup.object({
                    hours_of_consumption: Yup.number().max(4, commonValidationMessages.max4),
                })
            )
        })
    }

    /** Open close modal */
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function tog_backdrop() {
        setmodal_backdrop(!modal_backdrop);
        removeBodyCss();
    }

    /** Calculate sum of w */
    const loadwsum = (values: any[], field: string, form: any) => {
        let sum = 0;
        values?.map((val: any) => {
            sum += parseFloat(val.w);
            return '';
        })
        return sum.toFixed(2);
    }

    /** Fuel sub type consumption submit */
    const fuelSubTypeSubmit = (values: any) => {
        if (FuelSubTypeObject && FuelSubTypeObject?.length > 0) {
            values?.fuelSubTypeConsumption?.map((val: any) => {
                apiGlobal.put(`/special_operation_sub_type_fuel_consumption/${val.id}/`, val).then(res => {
                    if (res.status === 200) {
                        queryClient.invalidateQueries(queryKeyes.vessel.SpOpFuelSubTypeObject.key);
                    }
                })
                return '';
            });
        } else {
            if (values.fuelSubTypeConsumption?.length > 0) {
                apiGlobal.post(`/special_operation_sub_type_fuel_consumption/`, values.fuelSubTypeConsumption).then(res => {
                    if (res.status === 201) {
                        successToast("Data saved successfully!");
                        queryClient.invalidateQueries(queryKeyes.vessel.SpOpFuelSubTypeObject.key);
                    }
                }).catch(err => {
                    if (errResponse.includes(err.response.status)) {
                        errorToast("Internal error occured, please contact the admin");
                    }
                });
            }
        }
    }

    /** Special Operation submit function */
    const specialOperationSubmit = (values: any) => {
        /** Fuel consumption submit */
        if (FuelObject && FuelObject?.length > 0) {
            values.fuelConsumption.map((val: any) => {
                apiGlobal.put(`/special_operation_fuel/${val.id}/`, val).then(res => {
                    if (res.status === 200) {
                        queryClient.invalidateQueries(fuelQueryObj.variable);
                        if (env?.form_validation === true) {
                            toggleDynamicTabs(previousTabIndex + 1);
                        }
                    }
                }).catch(err => {
                    if (errResponse.includes(err.response.status)) {
                        errorToast("Internal error occured, please contact the admin");
                    }
                });
                return '';
            })
        } else {
            if (values.fuelConsumption?.length > 0) {
                apiGlobal.post(`/special_operation_fuel/`, values.fuelConsumption).then(res => {
                    if (res.status === 201) {
                        /** Fuel sub type consumption submit */
                        res.data.forEach((data: any) => {
                            values?.fuelSubTypeConsumption
                                .filter((item: any) => item.vessel_machinery_fc_group === data.vessel_machinery_fc_group &&
                                    item.fuel_type === data.vessel_fuel)?.forEach((batch: any) => {
                                        batch.special_operation_fuel = data.id;
                                    })
                        })
                        fuelSubTypeSubmit(values);
                        /** Fuel sub type consumption submit end */

                        successToast("Data saved successfully!");
                        queryClient.invalidateQueries(fuelQueryObj.variable);
                        if (env?.form_validation === true) {
                            toggleDynamicTabs(previousTabIndex + 1);
                        }
                    }
                }).catch(err => {
                    if (errResponse.includes(err.response.status)) {
                        errorToast("Internal error occured, please contact the admin");
                    }
                });
            }
        }

        /** Fuel sub type consumption submit */
        if (FuelSubTypeObject && FuelSubTypeObject?.length > 0) {
            fuelSubTypeSubmit(values);
        }

        /** Energy consumption with kWh meter submit */
        if (EnergyObject && EnergyObject?.length > 0) {
            values.withkWhMeter.functional.map((val: any) => {
                apiGlobal.put(`/special_operation_energy/${val.id}/`, val).then(res => {
                    if (res.status === 200) {
                        queryClient.invalidateQueries(energyQueryObj.variable);
                    }
                }).catch(err => {
                    if (errResponse.includes(err.response.status)) {
                        errorToast("Internal error occured, please contact the admin");
                    }
                });
                return '';
            })
        } else {
            if (values.withkWhMeter.functional?.length > 0) {
                apiGlobal.post(`/special_operation_energy/`, values.withkWhMeter.functional).then(res => {
                    if (res.status === 201) {
                        successToast("Data saved successfully!");
                        queryClient.invalidateQueries(energyQueryObj.variable);
                    }
                }).catch(err => {
                    if (errResponse.includes(err.response.status)) {
                        errorToast("Internal error occured, please contact the admin");
                    }
                });
            }
        }

        /** Energy consumption with non functional kWh meter submit */
        if (FourHrObject && FourHrObject?.length > 0) {
            values?.withkWhMeter?.nonfunctional?.map((val: any) => {
                if (val.id === undefined) {
                    apiGlobal.post(`/special_operation_4_hours_reporting/`, val).then(res => {
                        if (res.status === 201) {
                            successToast("Data saved successfully!");
                            queryClient.invalidateQueries(fourHrQueryObj.variable);
                        }
                    }).catch(err => {
                        if (errResponse.includes(err.response.status)) {
                            errorToast("Internal error occured, please contact the admin");
                        }
                    })
                } else {
                    apiGlobal.put(`/special_operation_4_hours_reporting/${val.id}/`, val).then(res => {
                        if (res.status === 200) {
                            queryClient.invalidateQueries(fourHrQueryObj.variable);
                        }
                    }).catch(err => {
                        if (errResponse.includes(err.response.status)) {
                            errorToast("Internal error occured, please contact the admin");
                        }
                    })
                }
                return '';
            })
        } else {
            if (values.withkWhMeter.nonfunctional?.length > 0) {
                apiGlobal.post(`/special_operation_4_hours_reporting/`, values.withkWhMeter.nonfunctional).then(res => {
                    if (res.status === 201) {
                        successToast("Data saved successfully!");
                        queryClient.invalidateQueries(fourHrQueryObj.variable);
                    }
                }).catch(err => {
                    if (errResponse.includes(err.response.status)) {
                        errorToast("Internal error occured, please contact the admin");
                    }
                });
            }
        }

        /** Energy consumption without kWh meter submit */
        if (FourHrObject && FourHrObject?.length > 0) {
            values.withoutkWhMeter.map((val: any) => {
                apiGlobal.put(`/special_operation_4_hours_reporting/${val.id}/`, val).then(res => {
                    if (res.status === 200) {
                        queryClient.invalidateQueries(fourHrQueryObj.variable);
                    }
                })
                return '';
            });
        } else {
            if (values.withoutkWhMeter?.length > 0) {
                apiGlobal.post(`/special_operation_4_hours_reporting/`, values.withoutkWhMeter).then(res => {
                    if (res.status === 201) {
                        successToast("Data saved successfully!");
                        queryClient.invalidateQueries(fourHrQueryObj.variable);
                    }
                }).catch(err => {
                    if (errResponse.includes(err.response.status)) {
                        errorToast("Internal error occured, please contact the admin");
                    }
                });
            }
        }
    }

    if (machineryFuelGroupLoading ||
        machineryEnergyGroupLoading ||
        fuelTypesLoading ||
        TimePeriodLoading ||
        FuelObjectLoading ||
        EnergyObjectLoading ||
        FourHrObjectLoading ||
        AuxEngineMaxSFOCLoading ||
        BatchConsumptionObjectLoading ||
        SpecialOpsObjectLoading ||
        FuelSubTypeObjectLoading ||
        FuelConsumptionObjectLoading ||
        fuelSettingsLoading ||
        FCElectricalLoading ||
        fuelQueryObj.variable === null ||
        fuelQueryObj.loadVariable === null ||
        energyQueryObj.variable === null ||
        energyQueryObj.loadVariable === null ||
        fourHrQueryObj.variable === null ||
        fourHrQueryObj.loadVariable === null ||
        fuelMachineQueryObj.variable === null ||
        fuelMachineQueryObj.loadVariable === null ||
        energyMachineQueryObj.variable === null ||
        energyMachineQueryObj.loadVariable === null
    ) {
        return <Loading message='Loading required data!' />
    }

    if (machineryFuelGroupError ||
        machineryEnergyGroupError ||
        fuelTypesError ||
        TimePeriodError ||
        AuxEngineMaxSFOCError ||
        BatchConsumptionObjectError ||
        SpecialOpsObjectError ||
        FuelConsumptionObjectError ||
        fuelSettingsError) {
        return <ErrorComponent message='Unable to load required data!' />
    }

    return (
        <Card className='p-0 mb-0 border-0'>
            <CardHeader className='p-2'>
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">Special Operation - {Operation}</h4>
                            <p className="card-title-desc pos-start">All readings since last report</p>
                        </Col>
                        <Col>
                            <ReportDetailsHeader />
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            {(machineryFuelGroupLoading ||
                machineryEnergyGroupLoading ||
                fuelTypesLoading ||
                TimePeriodLoading ||
                FuelObjectLoading ||
                EnergyObjectLoading ||
                FourHrObjectLoading ||
                AuxEngineMaxSFOCLoading ||
                BatchConsumptionObjectLoading ||
                SpecialOpsObjectLoading ||
                FuelSubTypeObjectLoading ||
                FuelConsumptionObjectLoading ||
                fuelSettingsLoading ||
                FCElectricalLoading ||
                fuelQueryObj.variable === null ||
                fuelQueryObj.loadVariable === null ||
                energyQueryObj.variable === null ||
                energyQueryObj.loadVariable === null ||
                fourHrQueryObj.variable === null ||
                fourHrQueryObj.loadVariable === null ||
                fuelMachineQueryObj.variable === null ||
                fuelMachineQueryObj.loadVariable === null ||
                energyMachineQueryObj.variable === null ||
                energyMachineQueryObj.loadVariable === null
            ) &&
                <Loading message='Loading required data!' />
            }
            {(machineryFuelGroupError ||
                machineryEnergyGroupError ||
                fuelTypesError ||
                TimePeriodError ||
                AuxEngineMaxSFOCError ||
                BatchConsumptionObjectError ||
                SpecialOpsObjectError ||
                FuelConsumptionObjectError ||
                fuelSettingsError) &&
                <ErrorComponent message='Unable to load required data!' />
            }
            {
                !(machineryFuelGroupLoading ||
                    machineryEnergyGroupLoading ||
                    fuelTypesLoading ||
                    TimePeriodLoading ||
                    FuelObjectLoading ||
                    EnergyObjectLoading ||
                    FourHrObjectLoading ||
                    AuxEngineMaxSFOCLoading ||
                    BatchConsumptionObjectLoading ||
                    SpecialOpsObjectLoading ||
                    FuelSubTypeObjectLoading ||
                    FuelConsumptionObjectLoading ||
                    fuelSettingsLoading ||
                    FCElectricalLoading) &&
                !(machineryFuelGroupError ||
                    machineryEnergyGroupError ||
                    fuelTypesError ||
                    TimePeriodError ||
                    AuxEngineMaxSFOCError ||
                    BatchConsumptionObjectError ||
                    SpecialOpsObjectError ||
                    FuelConsumptionObjectError ||
                    fuelSettingsError) &&
                (fuelQueryObj.variable !== null &&
                    fuelQueryObj.loadVariable !== null &&
                    energyQueryObj.variable !== null &&
                    energyQueryObj.loadVariable !== null &&
                    fourHrQueryObj.variable !== null &&
                    fourHrQueryObj.loadVariable !== null &&
                    fuelMachineQueryObj.variable !== null &&
                    fuelMachineQueryObj.loadVariable !== null &&
                    energyMachineQueryObj.variable !== null &&
                    energyMachineQueryObj.loadVariable !== null
                ) &&
                <Formik
                    onSubmit={(values: any, actions: any) => {
                        actions.setSubmitting(false);
                        specialOperationSubmit(values);
                    }}
                    initialValues={SpecialOperationFormik.initialValues}
                    validationSchema={SpecialOperationFormik.validationScehma}>
                    {({ values, errors, handleSubmit, handleChange, touched }:
                        { values: any, errors: any, handleSubmit: any, handleChange: any, touched: any }) => (
                        <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
                            <CardBody className='p-0'>
                                {
                                    (machineryFuelGroup && machineryFuelGroup?.length > 0) &&
                                    <Row className='px-2'>
                                        <Card className='mb-0 border-top-0 rounded-0 border-bottom-0'>
                                            <CardHeader className='p-2'>
                                                <div className="text-center">
                                                    <Row>
                                                        <Col className='ps-0'>
                                                            <h4 className="page_title pos-start mb-0">Fuel Consumption</h4>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </CardHeader>
                                            <CardBody className="p-0">
                                                <div className="table-responsive">
                                                    <table className="table mb-0">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th>Sr. No.</th>
                                                                <th className="text-left">Machinery name</th>
                                                                {
                                                                    fuelTypes && fuelTypes?.map((fuelType: any, index: number) => {
                                                                        return (
                                                                            <th key={index} className="asteric text-center">{fuelType.fuel_type_name}</th>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {machineryFuelGroup.map((vesselMachinery: any, index: number) => {
                                                                return (
                                                                    <tr key={vesselMachinery.id}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{vesselMachinery.vessel_machinery_fc_group_name}</td>
                                                                        <FieldArray name='fuelConsumption'>
                                                                            {() => (
                                                                                <>
                                                                                    {values?.fuelConsumption?.filter((item: any) => item.vessel_machinery_fc_group === vesselMachinery.vessel_machinery_fc_group)
                                                                                        .map((fuelConsumptions: any, index: number) => {
                                                                                            return (
                                                                                                <td key={index} className='text-center'>
                                                                                                    <SpOpFuelConsumptionComponent
                                                                                                        fuelConsumptions={fuelConsumptions}
                                                                                                        setTotalConsumption={setTotalConsumption}
                                                                                                        setFuelConsumed={setFuelConsumed}
                                                                                                        setFuelSubTypeConsumption={setFuelSubTypeConsumption}
                                                                                                        machineryEnabled={machineryEnabled(
                                                                                                            fuelSettings,
                                                                                                            VesselID,
                                                                                                            fuelConsumptions?.vessel_machinery_fc_group,
                                                                                                            fuelConsumptions?.vessel_fuel
                                                                                                        )}
                                                                                                    />
                                                                                                    {errors && !Object.keys(errors).includes('fuelConsumption') &&
                                                                                                        parseFloat(values?.fuelConsumption
                                                                                                        [values?.fuelConsumption?.indexOf(fuelConsumptions)]?.total_fuel_consumption) > 0 &&
                                                                                                        <SpOpFuelBatchConsumption
                                                                                                            state={fuelSubTypeConsumption}
                                                                                                            setState={setFuelSubTypeConsumption}
                                                                                                            id={index}
                                                                                                            machinery={values?.fuelSubTypeConsumption
                                                                                                                .filter((item: any) => item.vessel_machinery_fc_group ===
                                                                                                                    fuelConsumed.vessel_machinery_fc_group)}
                                                                                                            totalConsumption={values?.fuelConsumption[values?.fuelConsumption?.indexOf(fuelConsumptions)]?.total_fuel_consumption}
                                                                                                        />
                                                                                                    }
                                                                                                </td>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </>
                                                                            )
                                                                            }
                                                                        </FieldArray>
                                                                    </tr>
                                                                )
                                                            })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Row>
                                }{
                                    (withkWhMeter && withkWhMeter?.length > 0) &&
                                    <Row className='px-2'>
                                        <Card className='mb-0 border-top-0 rounded-0 border-bottom-0'>
                                            <CardHeader className='p-2'>
                                                <div className="text-center">
                                                    <Row>
                                                        <Col className='ps-0'>
                                                            <h4 className=" pos-start mb-0">Energy Consumption</h4>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </CardHeader>
                                            <div className="table-responsive">
                                                <table className="table mb-0">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="ps-2">Sr. No.</th>
                                                            <th className="p-2">Machinery name</th>
                                                            <th className="asteric text-center p-2">Energy consumed</th>
                                                            <th className="text-center  p-2">Equivalent fuel consumption</th>
                                                            <th className="text-center  p-2">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {withkWhMeter.map((vesselMachinery: any, index: number) => {
                                                            return (
                                                                <FieldArray name='functional'>
                                                                    {() => (
                                                                        <>
                                                                            {values?.withkWhMeter.functional
                                                                                .filter((item: any) => item.vessel_machinery_ec_group === vesselMachinery.vessel_machinery_ec_group)
                                                                                .map((energyConsumptions: any, index: number) => (
                                                                                    <>
                                                                                        <tr key={vesselMachinery.id}>
                                                                                            <td className='ps-3 align-middle'>{index + 1}</td>
                                                                                            <td className='align-middle'>{vesselMachinery.vessel_machinery_ec_group_name}</td>
                                                                                            <td className='align-middle text-center'>
                                                                                                <SpOpEnergyConsumptionComponent
                                                                                                    energyConsumptions={energyConsumptions}
                                                                                                    handleChange={handleChange}
                                                                                                    AuxEngineMaxSFOC={AuxEngineMaxSFOC}
                                                                                                    EnergyObject={EnergyObject}
                                                                                                    loadwsum={loadwsum}
                                                                                                />
                                                                                            </td>
                                                                                            <td className='text-center align-middle'>
                                                                                                <Label>{(AuxEngineMaxSFOC.max_eedi_eexi_technical_file *
                                                                                                    values.withkWhMeter.functional[values.withkWhMeter.functional.indexOf(energyConsumptions)].energy_consumed / 1000000).toFixed(4)}</Label>
                                                                                            </td>
                                                                                            <td className="align-middle text-center">
                                                                                                <Button
                                                                                                    className={`btn ${values.withkWhMeter.functional[values.withkWhMeter.functional.indexOf(energyConsumptions)]?.is_kwh_meter_not_functional === true ?
                                                                                                        'btn-danger' : 'functional-status-color'} btn_size_8_cstm`}
                                                                                                    onClick={() => {
                                                                                                        setModalMachinery(vesselMachinery);
                                                                                                        setModalIndex(values.withkWhMeter.functional.indexOf(energyConsumptions));
                                                                                                        tog_backdrop();
                                                                                                    }}
                                                                                                >
                                                                                                    Sensor failure
                                                                                                </Button>
                                                                                                <Modal
                                                                                                    isOpen={modal_backdrop}
                                                                                                    toggle={() => {
                                                                                                        tog_backdrop();
                                                                                                    }}
                                                                                                    backdrop={"static"}
                                                                                                    id="staticBackdrop"
                                                                                                    size={values.withkWhMeter.functional[modalIndex]?.is_kwh_meter_not_functional === true ? 'xl' : 'md'}
                                                                                                >
                                                                                                    <div className="modal-header">
                                                                                                        <h5 className="modal-title text-center" id="staticBackdropLabel">
                                                                                                            Action
                                                                                                        </h5>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn-close"
                                                                                                            onClick={() => {
                                                                                                                setmodal_backdrop(false);
                                                                                                            }}
                                                                                                            aria-label="Close"
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="modal-body">
                                                                                                        <strong>Please Note -</strong>
                                                                                                        <p>{TooltipMsg.Special_Operations
                                                                                                            .filter((item: any) => item.target ===
                                                                                                                'is_kWh_meter_functional_msg')
                                                                                                            .map((tool: any) => { return tool.message })}</p>
                                                                                                        <div className="d-flex flex-wrap gap-2 align-items-center">
                                                                                                            <Label htmlFor={`withkWhMeter.functional.${modalIndex}.is_kwh_meter_not_functional`}>
                                                                                                                kWh meter failure?
                                                                                                            </Label>
                                                                                                            <div className="square-switch sqswitch d-flex align-items-center">
                                                                                                                <Field name={`withkWhMeter.functional.${modalIndex}.is_kwh_meter_not_functional`}>
                                                                                                                    {({ field, form }: FieldProps) => (
                                                                                                                        <Input
                                                                                                                            type="checkbox"
                                                                                                                            switch="none"
                                                                                                                            name={field.name}
                                                                                                                            id={field.name}
                                                                                                                            onChange={(e: any) => handleChange(e)}
                                                                                                                            checked={values.withkWhMeter.functional[modalIndex].is_kwh_meter_not_functional}
                                                                                                                        />
                                                                                                                    )}
                                                                                                                </Field>
                                                                                                                <Label htmlFor={`withkWhMeter.functional.${modalIndex}.is_kwh_meter_not_functional`}
                                                                                                                    data-on-label='Yes' data-off-label='No'>
                                                                                                                </Label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {(SpecialOpsObject && SpecialOpsObject[0]?.constant === SpecialOpsConstant.REEFER_CONTAINER &&
                                                                                                            values.withkWhMeter.functional[modalIndex]?.is_kwh_meter_not_functional === true) ?
                                                                                                            <Card className='mb-0'>
                                                                                                                <CardHeader className='p-2'>
                                                                                                                    <Row>
                                                                                                                        <Col className='ps-2'>
                                                                                                                            <h4 className="page_title mb-0">FC<sub>electrical_reefer</sub></h4>
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                </CardHeader>
                                                                                                                <CardBody className="p-2">
                                                                                                                    <Row className='mb-2'>
                                                                                                                        <Col lg={4}>
                                                                                                                            <Label for="reefer_days_sea" className='mb-0'>Reefer days at sea
                                                                                                                                <i className='bx bx-info-circle ml-2p' id='reefer_days_sea_msg' />:
                                                                                                                            </Label>
                                                                                                                            <ToolTip target='reefer_days_sea_msg'
                                                                                                                                message={`${TooltipMsg.Reefer_Container
                                                                                                                                    .filter((item: any) => item.target === 'reefer_days_sea_msg')
                                                                                                                                    .map((tool: any) => { return tool.message })}`}
                                                                                                                            />
                                                                                                                            <strong>{values?.withkWhMeter?.nonfunctional[0]?.reefer_days_at_sea}</strong>
                                                                                                                        </Col>
                                                                                                                        <Col lg={4}>
                                                                                                                            <Label for="reefer_days_port" className='mb-0'>Reefer days in port
                                                                                                                                <i className='bx bx-info-circle ml-2p' id='reefer_days_port_msg' />:
                                                                                                                            </Label>
                                                                                                                            <ToolTip target='reefer_days_port_msg'
                                                                                                                                message={`${TooltipMsg.Reefer_Container
                                                                                                                                    .filter((item: any) => item.target === 'reefer_days_port_msg')
                                                                                                                                    .map((tool: any) => { return tool.message })}`}
                                                                                                                            />
                                                                                                                            <strong>{values?.withkWhMeter?.nonfunctional[0]?.reefer_days_in_port}</strong>
                                                                                                                        </Col>
                                                                                                                        <Col lg={4}>
                                                                                                                            <Label for="reefer_equivalent_fuel_consumption" className='mb-0'>Equivalent fuel consumption
                                                                                                                                <i className='bx bx-info-circle ml-2p' id='reefer_equivalent_fuel_consumption_msg' />:
                                                                                                                            </Label>
                                                                                                                            <ToolTip target='reefer_equivalent_fuel_consumption_msg'
                                                                                                                                message={`${TooltipMsg.Reefer_Container
                                                                                                                                    .filter((item: any) => item.target === 'reefer_equivalent_fuel_consumption_msg')
                                                                                                                                    .map((tool: any) => { return tool.message })}`}
                                                                                                                            />
                                                                                                                            <strong>{values?.withkWhMeter?.nonfunctional[0]?.equivalent_fuel_consumption}</strong>
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                    <Row>
                                                                                                                        <Col lg={4}>
                                                                                                                            <Label for="fc_electrical" className='mb-0'>FC<sub>electrical_reefer</sub>
                                                                                                                                <i className='bx bx-info-circle ml-2p' id='fc_electrical_msg' />:
                                                                                                                            </Label>
                                                                                                                            <ToolTip target='fc_electrical_msg'
                                                                                                                                message={`${TooltipMsg.Reefer_Container
                                                                                                                                    .filter((item: any) => item.target === 'fc_electrical_msg')
                                                                                                                                    .map((tool: any) => { return tool.message })}`}
                                                                                                                            />
                                                                                                                            <strong>{values?.withkWhMeter?.nonfunctional[0]?.fc_electrical_reefer}</strong>
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                </CardBody>
                                                                                                            </Card> :
                                                                                                            values.withkWhMeter.functional[modalIndex]?.is_kwh_meter_not_functional === true ?
                                                                                                                <FourHrTableComponent
                                                                                                                    vesselMachinery={modalMachinery}
                                                                                                                    lastRecord={lastRecord}
                                                                                                                    record={record}
                                                                                                                    tableHeader={'Hours of cargo cooling'}
                                                                                                                    handleChange={handleChange}
                                                                                                                    valueName={'withkWhMeter.nonfunctional'}
                                                                                                                    valueSubName={values?.withkWhMeter?.nonfunctional}
                                                                                                                    energyConsumedField={`withkWhMeter.functional[${modalIndex}].energy_consumed`}
                                                                                                                    showMachineryName={false}
                                                                                                                    applicableTimePeriod={applicableTimePeriod}
                                                                                                                /> : null
                                                                                                        }
                                                                                                    </div>
                                                                                                </Modal>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </>
                                                                                ))
                                                                            }
                                                                        </>
                                                                    )}
                                                                </FieldArray>
                                                            )
                                                        })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Card>
                                    </Row>
                                }
                                {(withoutkWhMeter && withoutkWhMeter?.length > 0) &&
                                    (SpecialOpsObject && SpecialOpsObject[0]?.constant === SpecialOpsConstant.REEFER_CONTAINER) ?
                                    <Card className='mb-0 border-top-0 rounded-0 border-bottom-0'>
                                        <CardHeader className='p-2'>
                                            <Row>
                                                <Col className='ps-2'>
                                                    <h4 className="page_title mb-0">FC<sub>electrical_reefer</sub></h4>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody className="p-2">
                                            <Row className='mb-2'>
                                                <Col lg={4}>
                                                    <Label for="reefer_days_sea" className='mb-0'>Reefer days at sea
                                                        <i className='bx bx-info-circle ml-2p' id='reefer_days_sea_msg' />:
                                                    </Label>
                                                    <ToolTip target='reefer_days_sea_msg'
                                                        message={`${TooltipMsg.Reefer_Container
                                                            .filter((item: any) => item.target === 'reefer_days_sea_msg')
                                                            .map((tool: any) => { return tool.message })}`}
                                                    />
                                                    <strong>{values?.withkWhMeter?.nonfunctional[0]?.reefer_days_at_sea}</strong>
                                                </Col>
                                                <Col lg={4}>
                                                    <Label for="reefer_days_port" className='mb-0'>Reefer days in port
                                                        <i className='bx bx-info-circle ml-2p' id='reefer_days_port_msg' />:
                                                    </Label>
                                                    <ToolTip target='reefer_days_port_msg'
                                                        message={`${TooltipMsg.Reefer_Container
                                                            .filter((item: any) => item.target === 'reefer_days_port_msg')
                                                            .map((tool: any) => { return tool.message })}`}
                                                    />
                                                    <strong>{values?.withkWhMeter?.nonfunctional[0]?.reefer_days_in_port}</strong>
                                                </Col>
                                                <Col lg={4}>
                                                    <Label for="reefer_equivalent_fuel_consumption" className='mb-0'>Equivalent fuel consumption
                                                        <i className='bx bx-info-circle ml-2p' id='reefer_equivalent_fuel_consumption_msg' />:
                                                    </Label>
                                                    <ToolTip target='reefer_equivalent_fuel_consumption_msg'
                                                        message={`${TooltipMsg.Reefer_Container
                                                            .filter((item: any) => item.target === 'reefer_equivalent_fuel_consumption_msg')
                                                            .map((tool: any) => { return tool.message })}`}
                                                    />
                                                    <strong>{values?.withkWhMeter?.nonfunctional[0]?.equivalent_fuel_consumption}</strong>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={4}>
                                                    <Label for="fc_electrical" className='mb-0'>FC<sub>electrical_reefer</sub>
                                                        <i className='bx bx-info-circle ml-2p' id='fc_electrical_msg' />:
                                                    </Label>
                                                    <ToolTip target='fc_electrical_msg'
                                                        message={`${TooltipMsg.Reefer_Container
                                                            .filter((item: any) => item.target === 'fc_electrical_msg')
                                                            .map((tool: any) => { return tool.message })}`}
                                                    />
                                                    <strong>{values?.withkWhMeter?.nonfunctional[0]?.fc_electrical_reefer}</strong>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card> :
                                    (withoutkWhMeter && withoutkWhMeter?.length > 0) &&
                                        (SpecialOpsObject && SpecialOpsObject[0]?.constant !== SpecialOpsConstant.REEFER_CONTAINER) ?
                                        <Row className='px-2'>
                                            <CardHeader className='p-2'>
                                                <div className="text-center">
                                                    <Row>
                                                        <Col className='ps-0'>
                                                            <h4 className="page_title pos-start mb-0">Energy consumption without kWh meter</h4>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </CardHeader>
                                            {withoutkWhMeter.map((vesselMachinery: any) => {
                                                return (
                                                    <FourHrTableComponent
                                                        vesselMachinery={vesselMachinery}
                                                        lastRecord={lastRecord}
                                                        record={record}
                                                        tableHeader={FourHrTblHeader}
                                                        handleChange={handleChange}
                                                        valueName={'withoutkWhMeter'}
                                                        valueSubName={values?.withoutkWhMeter}
                                                        showMachineryName={true}
                                                        energyConsumedField={''}
                                                        applicableTimePeriod={applicableTimePeriod}
                                                    />
                                                )
                                            })}
                                        </Row> :
                                        null
                                }
                            </CardBody>
                            <CardFooter className='p-2'>
                                <Row className="ele_row1">
                                    <div className="d-flex flex-wrap gap-5">
                                        <Button type="submit" color="primary" className="btn_size_cstm pos-end" onClick={(e) => {
                                            if (env?.form_validation === false) {
                                                toggleDynamicTabs(previousTabIndex + 1);
                                            }
                                        }}>Next <i className="bx bx-chevron-right ms-1" /></Button>
                                        {tabsIdList[0] === tabsList
                                            .filter((item: any) => item.tab === tab)[0]?.id && (record && record.operation_precedence_id === 1) ?
                                            <Button type="button" color="primary" className="btn_size_cstm" onClick={(e) => {
                                                toggleTab(tabsList[0]?.id - 1);
                                            }}><i className="bx bx-chevron-left me-1" /> Previous</Button> :
                                            tabsIdList[0] === tabsList
                                                .filter((item: any) => item.tab === tab)[0]?.id && (record && record.operation_precedence_id !== 1) ?
                                                <Button type="button" color="primary" className="btn_size_cstm" onClick={(e) => {
                                                    toggleTab(tabsList[0]?.id - 3);
                                                }}><i className="bx bx-chevron-left me-1" /> Previous</Button> :
                                                <Button type="button" color="primary" className="btn_size_cstm" onClick={(e) => {
                                                    toggleDynamicTabs(previousTabIndex - 1);
                                                }}><i className="bx bx-chevron-left me-1" /> Previous</Button>
                                        }
                                    </div>
                                </Row>
                            </CardFooter>
                            <FormValuesDebug values={[values, errors, SpecialOperationFormik.initialValues]} />
                        </Form>
                    )}
                </Formik>
            }
        </Card >
    )
}

export default SpecialOperationComponent